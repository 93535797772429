import React from 'react';
import loading from '../assets/loading.webp';

export default function FullScreenLoading() {
  return (
    <div style={{ position: "fixed", height: "100vh", width: "100vw", top: 0, bottom: 0 }}>
      <div style={{ position: "relative", top: '8px', width: "64vw", maxWidth: "64vh", margin: "0 auto", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <img src={loading} alt='loading...' style={{ width: '100%' }} />
      </div >
    </div>
  );
}

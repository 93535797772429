import React, { useState } from 'react';
import { Box, Fade, IconButton, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function CopyInviteLinkToClipboard({ gameId }) {
  const [open, setOpen] = useState(false);

  const handleCopyGameLink = () => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText('www.icanrunlowkey.com/inbox/' + gameId);
    }

    setOpen(true);
    setTimeout(() => setOpen(false), 1000);
  };

  return (
    <Box onClick={handleCopyGameLink} sx={{ cursor: 'pointer' }}>
      <IconButton size='small'>
        <ContentCopyIcon />
      </IconButton>
      {!open ? (
        <Typography variant='caption'>
          Copy Invite Link to Share with Non Users
        </Typography>
      ) : (
        <Fade in={open} timeout={1000}>
          <Typography component='span' variant='caption' color='success'>
            Copied!
          </Typography>
        </Fade>)
      }
    </Box>
  );
}

export const getTopPlayerCount = (game) => {
  return Math.max(...game.options.map(o => o.playerCount));
};

export const getSpotsLeft = (game) => {
  return game.numbersRequiredMax - getTopPlayerCount(game);
};

export const getSpotsNeeded = (game) => {
  return game.numbersRequiredMin - getTopPlayerCount(game);
};

export const findWinningOption = (game) => {
  const option = game.options.find(o => {
    return o.playerCount === getTopPlayerCount(game);
  });

  return option;
};

export const gameOn = (game) => {
  return getTopPlayerCount(game) >= game.numbersRequiredMin;
};

export const fullGame = (game) => {
  return getTopPlayerCount(game) >= game.numbersRequiredMax;
};

export const getProgress100 = (game) => {
  return Math.min(getTopPlayerCount(game) / game.numbersRequiredMin * 100, 100);
};

export const userIsInWinningOption = (game, userId) => {
  return findWinningOption(game).players.map(p => p._id).includes(userId);
};

export const inviteMoreFriends = (game) => {
  return game.invited.length - (game?.out?.length || 0) + 1 < game.numbersRequiredMin;
};

export const filterArrByArr = (inArray, outArray) => {
  if (outArray.length === 0) {
    return inArray
  }
  
  return inArray.filter(({ _id }) => {
    return outArray.map(p => p._id).indexOf(_id) === -1;
  });
};

export const gameExpired = (game) => {
  let latestOption = 0;

  if (gameOn(game)) {
    latestOption = parseInt(findWinningOption(game).datetime)
  } else {
    for (let i = 0; i < game.options.length; i++) {
      if (parseInt(game.options[i].datetime) > latestOption) {
        latestOption = parseInt(game.options[i].datetime);
      }
    }
  }
  // expire one hour after game time
  return Date.now() >= latestOption + 3600000;
};

export const renderPillText = (game, userId) => {
  const inWinningOption = userIsInWinningOption(game, userId);
  const spotsLeft = getSpotsLeft(game);
  let spots = spotsLeft === 1 ? "spot" : "spots";
  const spotsNeeded = getSpotsNeeded(game);
  const players = spotsNeeded === 1 ? "player" : "players";
  if (gameExpired(game)) return `Game Expired`
  if (spotsNeeded > 0) return `${spotsNeeded} ${players} needed`;
  if (fullGame(game)) return inWinningOption ? "Full Game!" : "No spots left";
  if (gameOn(game)) return `Game on! ${spotsLeft} ${spots} left`;
  return `${spotsLeft} ${spots} left`;
};

const success = '#1DB45A';
const info = '#29b6f6';
const error = '#FF505F';
const warning = '#F1A204';
const grey = '#AAB4BE'

export const getInboxPillColor = (game, userId) => {
  const inWinningOption = userIsInWinningOption(game, userId);
  if (gameExpired(game) > 0) return grey;
  if (getSpotsNeeded(game) > 0) return info;
  if (fullGame(game)) return inWinningOption ? success : error;
  if (gameOn(game)) return inWinningOption ? success : warning;
  return info;
};

export const getManageGamesPillColor = (game) => {
  const spotsNeeded = getSpotsNeeded(game);
  if (gameExpired(game) > 0) return grey;
  if (inviteMoreFriends(game)) return 'red';
  if (spotsNeeded > 3) return error;
  if (spotsNeeded > 0) return warning;
  if (fullGame(game)) return success;
  return info;
};
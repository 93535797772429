import React, { useRef, useState } from 'react';
import { Button, TextField, Slider, Typography, useMediaQuery, FormLabel, Fade, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import OptionInput from '../components/OptionInput';
import { CREATE_GAME } from '../utils/mutations';
import { USER } from '../utils/queries';
import { useMutation } from '@apollo/client';
import Auth from '../utils/auth';
import { useNavigate } from "react-router-dom";
import InviteFriends from '../components/InviteFriends';
import FullScreenLoading from 'components/FullScreenLoading';
// import Loading from '../components/Loading';

export default function CreateGame() {
  const navigate = useNavigate();

  /* State */
  const initialFormState = { name: '', numbersRequired: [6, 10], open: 'TRUE', options: [] };
  const initialGameOptions = [{ location: "", datetime: null }];
  const [form, setForm] = useState(initialFormState);
  const [gameOptions, setGameOptions] = useState(initialGameOptions);
  const [invited, setInvited] = useState([]);
  const [sliderActive, setSliderActive] = useState(false);

  /* Style */
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = {
    formItem: {
      boxSizing: 'content-box',
      padding: '16.5px 14px',
      borderRadius: '4px',
      border: '1px solid #3f444a',
      boxShadow: '1px solid #3f444a',
      ':hover': {
        border: '1px solid white',
      },
    },
    formItemSlider: {
      boxSizing: 'content-box',
      padding: '16.5px 14px',
      borderRadius: '4px',
      border: sliderActive ? '1px solid #3090ef' : '1px solid #3f444a',
      boxShadow: sliderActive ? '0 0 0 1px #3090ef' : '1px solid #3f444a',
      ':hover': {
        border: sliderActive ? '1px solid #3090ef' : '1px solid white',
      },
    },
  };

  /* Handlers */
  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
  };


  /* Slider Handler Start */
  const isTouchScreen = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
  const maxValue = 20;
  const minValue = 2;
  const sliderRef = useRef(null);
  const getClamp = (x, v, activeThumb) => {
    const l = sliderRef.current.getBoundingClientRect().left;
    const w = sliderRef.current.getBoundingClientRect().width;
    if (activeThumb === 0) {
      return Math.floor(Math.max(Math.min((x - l) / w * (maxValue - minValue) + minValue, maxValue), v));
    } else {
      return Math.ceil(Math.min(Math.max((x - l) / w * (maxValue - minValue) + minValue, minValue), v));
    }
  };

  const handleChangeSlider = (event, newValue, activeThumb) => {
    // Type safety
    if (!Array.isArray(newValue)) {
      return;
    };
    
    // Type safety
    if (!sliderRef.current) {
      console.log('Issue: !sliderRef.current');
      return;
    }

    // Solves Mobile Bug: mousedown event on touchscreen causes slider to snap back
    if (isTouchScreen && event.type === 'mousedown') {
      return;
    }

    // Use Material UI Default When Range
    if (newValue[1] - newValue[0] > 0) {
      setForm({ ...form, numbersRequired: newValue });
    } 
    
    // Custom Slider Clamping Logic When Together
    else {
      const x = event.clientX || event.touches[0].clientX;
      const v = newValue[0];
      const clamped = getClamp(x, v, activeThumb);
      setForm({ ...form, numbersRequired: [clamped, clamped] });
    }
  };
  /* Slider Handler End */

  /* Apollo Hooks */
  const [CreateGame, { loading, error: createGameError }] = useMutation(CREATE_GAME);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (Auth.getProfile()?.data?._id) {
      try {
        const res = await CreateGame({
          variables: {
            ...form,
            open: form.open === 'TRUE',
            numbersRequiredMin: parseInt(form.numbersRequired[0]),
            numbersRequiredMax: parseInt(form.numbersRequired[1]),
            timezoneOffset: new Date().getTimezoneOffset(),
            options: [...gameOptions],
            invited: invited.map(x => x._id),
          },
          update: (cache, { data }) => {
            try {
              const pv = cache.readQuery({
                query: USER,
              });

              cache.writeQuery({
                query: USER,
                data: {
                  user: {
                    ...pv.user,
                    myGames: [data.createGame, ...pv.user.myGames]
                  }
                }
              });

            } catch (err) {
              console.log(err);
            }
          }
        });

        setForm(initialFormState);
        setGameOptions(initialGameOptions);

        if (res) navigate('/manage');

      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    loading ? <FullScreenLoading /> : <>
      <Box
        component="form"
        id="create-game-form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: sm ? '95%' : '80%',
          backgroundColor: 'hsl(212, 61%, 5%)',
          border: '1px solid #132F4C',
          borderRadius: '5px',
          padding: sm ? '16px' : '32px',
          marginTop: '18px',
        }}
        onSubmit={handleSubmit}
      >
        <Typography variant={sm ? "h6" : "h4"} fontWeight='bold' component="h2" sx={{ flexGrow: 1, overflow: 'hidden' }}>
          Create Game
        </Typography>

        <TextField
          name="name"
          type="text"
          label={'Name'}
          color={'primary'}
          fullWidth
          value={form.name}
          onChange={handleChange}
        />

        {/* SLIDER */}
        <Box
          component='div'
          sx={styles.formItemSlider}
          onMouseDown={() => setSliderActive(true)}
          onMouseUp={() => setSliderActive(false)}
        >
          <FormLabel
            sx={{ color: sliderActive ? '#3090ef' : 'none' }}
          >
            {`Players Needed (${form.numbersRequired[0] === form.numbersRequired[1] ? form.numbersRequired[0] : form.numbersRequired[0] + " - " + form.numbersRequired[1]})`}
          </FormLabel>
          <Slider
            ref={sliderRef}
            disableSwap
            getAriaLabel={() => 'min max players needed slider'}
            name="numbersRequired"
            type="number"
            label={'Players Needed'}
            color={'primary'}
            min={minValue}
            max={maxValue}
            step={1}
            value={form.numbersRequired}
            valueLabelDisplay="off"
            onChange={handleChangeSlider}
          />
        </Box>

        <FormControl sx={styles.formItem}>
          <FormLabel id="open-or-closed-game">
            {form.open === "TRUE" ? "Anyone With Link Can Join" : "Only You Can Invite Friends"}
          </FormLabel>
          <RadioGroup
            aria-labelledby="open"
            defaultValue={'TRUE'}
            name="open"
            value={form.open}
            onChange={handleChange}
          >
            <FormControlLabel value={'TRUE'} control={<Radio />} label="Open" />
            <FormControlLabel value={"FALSE"} control={<Radio />} label="Closed" />
          </RadioGroup>
        </FormControl>


        {gameOptions.map((x, i) => <OptionInput
          setGameOptions={setGameOptions}
          gameOptions={gameOptions}
          i={i}
          key={i}
        />)}
        <Button
          className='add-option-button'
          variant="outlined"
          color="secondary"
          style={{ marginTop: '40px' }}
          onClick={() => {
            setGameOptions([...gameOptions, { location: gameOptions[gameOptions.length - 1].location, datetime: gameOptions[gameOptions.length - 1].datetime }]);
          }}
        >
          Add Option
        </Button>

        <InviteFriends invited={invited} setInvited={setInvited} />

        <Button variant="outlined" type="submit">
          Submit
        </Button>

        {createGameError && <Fade in={typeof createGameError != "undefined"} timeout={1000}>
          <Typography component='p' variant='subtitle1' color="error">
            Something went wrong. Please review your inputs.
          </Typography>
        </Fade>}
      </Box>
    </>
  );
};

import { useState, useEffect } from 'react';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  /* resize */
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);

  }, []);

  /* orientation change */
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    
    window.addEventListener('orientationchange', handleResize);

    return () => window.removeEventListener('orientationchange', handleResize);

  }, []);

  return windowDimensions;
}

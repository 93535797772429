import React, { useEffect, useState } from 'react';
import { Button, TextField, Fade, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SEND_PASSWORD_RESET_LINK } from 'utils/queries';
import { UPDATE_PASSWORD } from 'utils/mutations';
import { useLocation } from 'react-router-dom';
import Auth from '../utils/auth';

export default function Signin() {
  /* Router */
  const { search } = useLocation();
  /* Style */
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  /* State */
  const [authenticated, setAuthenticated] = useState(false);
  const [form, setForm] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [unmatchedPassword, setUnmatchedPassword] = useState(false);

  /* Apollo Hooks */
  /* Queries */
  const [sendResetLink, { data, error }] = useLazyQuery(SEND_PASSWORD_RESET_LINK);

  /* Mutations */
  const [updatePassword, { error: updatePasswordError }] = useMutation(UPDATE_PASSWORD);

  const handleEmailError = (e) => {
    const regexp = /^\S+@\S+\.\S+$/gi;
    const valid = e.target.value.trim().match(regexp)
      ? (e.target.value.trim().match(regexp)[0] === e.target.value.trim())
      : false;
    setValidEmail(valid);
  };

  useEffect(() => {
    if (search) {
      const queryArray = search.split(/[=?&]/)
      const email = queryArray[2];
      const oldPassword = queryArray[4];
      setEmail(email);
      setOldPassword(oldPassword);
      setAuthenticated(true);
    }
  }, [search]);

  /* Handlers */
  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setUnmatchedPassword(false);
    if (!authenticated) {
      // SEND THE AUTHENTICATION LINK TO USER
      try {
        await sendResetLink({ variables: { email: form.email } });
      } catch (error) {
        console.log(error);
      }
      // RESET PASSWORD
    } else {
      if (form.password === form.confirmPassword) {
        try {
          const { data } = await updatePassword({
            variables: { email, oldPassword, newPassword: form.password }
          });

          Auth.login(data.updatePassword.token);

        } catch (err) {
          console.log(err);
        }
      } else {
        setUnmatchedPassword(true);
      }
    };
  };

  return (
    <div className='vertical-center' style={{
      width: sm ? '95%' : '80%',
      maxWidth: '550px',
      top: 'max(50%, 258px)', //EDIT_ME
    }}>
      <Box
        component="form"
        id="signup-form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'hsl(212, 61%, 5%)',
          border: '1px solid #132F4C',
          borderRadius: '5px',
          padding: sm ? '16px' : '32px',
        }}
        onSubmit={handleSubmit}
      >
        <Typography fontWeight='bold' variant={sm ? "h6" : "h4"} component="h2" sx={{ flexGrow: 1 }}>
          {authenticated ? "Enter your new password" : "Enter your email"}
        </Typography>

        {!authenticated && <TextField
          name="email"
          type="email"
          label={validEmail ? 'Email' : 'Enter a valid email'}
          color={validEmail ? 'primary' : 'error'}
          fullWidth
          value={form.email}
          onChange={handleChange}
          onBlur={handleEmailError}
        />}

        {authenticated && <TextField
          name="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          value={form.password}
          onChange={handleChange}
          fullWidth
        />}

        {authenticated && <TextField
          color={form.password === form.confirmPassword ? 'success' : 'warning'}
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          autoComplete="current-password"
          value={form.confirmPassword}
          onChange={handleChange}
          fullWidth
        />}

        <Button variant="outlined" type="submit">
          {authenticated ? "Reset Password" : "Get Reset Link"}
        </Button>

        {unmatchedPassword && <Fade in={unmatchedPassword} timeout={1000}>
          <Typography component='p' variant='subtitle1' color='error'>
            Your passwords don't match.
          </Typography>
        </Fade>}

        {!!error && !data && <Fade in={!!error} timeout={1000}>
          <Typography component='p' variant='subtitle1' color='error'>
            {error?.message}
          </Typography>
        </Fade>}

        {!!data && <Fade in={!!data} timeout={1000}>
          <Typography component='p' variant='subtitle1' color='#1DB45A'>
            {data?.sendPasswordResetLink?.message}
          </Typography>
        </Fade>}
        
        {!!updatePasswordError && <Fade in={!!updatePasswordError} timeout={1000}>
          <Typography component='p' variant='subtitle1' color='error'>
            {updatePasswordError?.message}
          </Typography>
        </Fade>}

      </Box>
    </div>
  );
};


import { Box, Typography } from '@mui/material';
import Loading from 'components/Loading';
import React from 'react';

export default function BannedAccount(props) {

  return (
    <div>
      <Box className='d-flex-center' height={'200px'}>
        <Loading />
      </Box>

      <Typography padding={10} width={'100vw'} color={'#ff0102'} textAlign='center'>
        This Account Has Been Banned
        <br/>
        <br/>
        If you would like more information feel free to email gameon@icanrunlowkey.com
      </Typography>
    </div>
  );
}

import React from 'react';
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { REMOVE_FRIEND, ADD_FRIEND } from '../utils/mutations';
import { PAGINATED_USERS, USER } from '../utils/queries';
import PeopleIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function SmallPeopleList({ players = [], listTitle = "People" }) {
  /* Style */
  const regex = /\b\S/g;
  const getAvatarText = (string) => {
    try {
      const array = string.match(regex);
      return array[0] + (array[1] ?? '');
    } catch (err) {
      console.log(err);
      return "😓";
    }
  };

  const { data, loading } = useQuery(USER, {
    notifyOnNetworkStatusChange: true
  });

  const friends = data?.user.friends ?? [];
  const isFriend = (_id) => friends.map(f => f._id)?.includes(_id);

  const [addFriend] = useMutation(ADD_FRIEND);
  const [removeFriend] = useMutation(REMOVE_FRIEND);

  const refetchQueries = [
    {
      query: PAGINATED_USERS,
      variables: {
        cursor: null,
        limit: 50,
        relation: "FRIENDS",
        searchTerm: '',
      }
    },
    {
      query: PAGINATED_USERS,
      variables: {
        cursor: null,
        limit: 50,
        relation: "NON_FRIENDS",
        searchTerm: '',
      }
    },
  ];

  const update = (cache, { data }) => {
    try {
      const pv = cache.readQuery({
        query: USER,
      });

      cache.writeQuery({
        query: USER,
        data: {
          user: {
            ...pv.user,
            __typename: "User",
            friends: data.addFriend
              ? [...pv.user.friends, data.addFriend]
              : pv.user.friends.filter(f => f._id !== data.removeFriend._id)
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickAddOrRemoveFriend = async (_id, event) => {
    let mutateFunction;

    switch (true) {
      case isFriend(_id):
        mutateFunction = removeFriend;
        break;

      default:
        mutateFunction = addFriend;
    }

    event.stopPropagation();
    try {
      await mutateFunction({
        variables: { _id },
        update,
        refetchQueries,
      });
    }
    catch (err) {
      console.log(err);
    }
  };

  return (
    loading || !players.length
      ? <></>
      : (
        <div>
          <Typography sx={{
            fontSize: '20px',
            borderBottom: "1px solid hsl(212, 61%, 18%)",
            padding: '14px 0 0 0',
          }}
            variant="subtitle" component="h3"
          >
            {listTitle}
          </Typography>
          <List>
            {players.map(p =>
              <ListItem
                key={p._id}
                sx={{
                  borderBottom: "1px solid #132f4c",
                  display: 'flex',
                  '&:last-of-type': { borderBottom: 'none' },
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label={isFriend(p._id) ? `remove friend` : `add friend`}
                    onClick={(e) => handleClickAddOrRemoveFriend(p._id, e)}
                    disabled={data.user._id === p._id}
                  >
                    {data.user._id === p._id
                      ? <AccountCircleIcon color='primary' />
                      : isFriend(p._id) ? <PeopleIcon color='success' /> : <PersonAddIcon />
                    }
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: '#132f4c', color: 'white' }}>
                    <Typography variant="overline" component={"h4"} sx={{ textAlign: 'center' }}>
                      {getAvatarText(p.fullName)}
                    </Typography>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={p.fullName}
                />
              </ListItem>
            )}
          </List>
        </div>
      )
  );
}

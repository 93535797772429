import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  getTopPlayerCount,
  gameOn,
  getProgress100,
  findWinningOption,
  filterArrByArr,
  gameExpired
} from '../utils/optionHelpers';
import PlayersModal from './PlayersModal';
export default function CircularGameProgress({ game, status, sz = 35, showNumber = true }) {
  const [open, setOpen] = useState(false);
  const toggleSetOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      <Box
        onClick={toggleSetOpen}
        sx={{
          position: 'relative',
          right: 0,
          top: '8px',
          display: 'flex',
          transform: 'rotate(180deg)',
        }}>
        <CircularProgress
          variant="determinate"
          value={getProgress100(game)}
          color={gameExpired(game) || status === 'read' ? "neutral" : gameOn(game) ? "success" : "inherit"}
          sx={{
            backgroundColor: '#132f4c',
            borderRadius: '50%',
            maxHeight: sz,
            maxWidth: sz,
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            height: sz,
            width: sz,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography fontSize={sz/3} sx={{ transform: 'rotate(180deg)' }} variant="overline" component="div" color={gameOn(game) ? "success" : "inherit"}>
            { showNumber && getTopPlayerCount(game) }
          </Typography>
        </Box>
      </Box>

      <PlayersModal
        open={open}
        setOpen={setOpen}
        lists={
          [
            {
              title: "Playing",
              items: findWinningOption(game).players,
            },
            {
              title: "Invited",
              items: filterArrByArr(game.invited, findWinningOption(game).players),
            },
          ]
        }
      />
    </>
  );
}


import gql from 'graphql-tag';

export const USER = gql` query User {
  user {
    _id
    fullName
    friends {
      _id
			fullName
    }
    myGames {
      _id
      name
      open
      numbersRequiredMin
      numbersRequiredMax
      createdAt
      owner {
        _id
        fullName
      }
      options {
        _id
        playerCount
        datetime
        location
        players {
          _id
          fullName
        }
      }
      invited {
        _id
        fullName
      }
    }
    gameInvites {
      _id
      status
      game {
        _id
        name
        open
        numbersRequiredMin
        numbersRequiredMax
        createdAt
        owner {
          _id
          fullName
        }
        options {
          _id
          playerCount
          datetime
          location
          players {
            _id
            fullName
          }
        }
        invited {
          _id
          fullName
        }
      }
    }
  }
}
`;

export const SEND_PASSWORD_RESET_LINK = gql`
  query SendPasswordResetLink($email: String!) {
    sendPasswordResetLink(email: $email) {
      message
    }
  }
`

export const GAME = gql`
query Game($_id: ID!) {
  game(_id: $_id) {
    _id
    name
    open
    numbersRequiredMin
    numbersRequiredMax
    createdAt
    owner {
      _id
      fullName
    }
    invited {
      _id
      fullName
    }
    out {
      _id
      fullName
    }
    options {
      _id
      playerCount
      datetime
      location
      players {
        _id
        fullName
      }
    }
  }
}
`;

export const PAGINATED_USERS = gql`
query PaginatedUsers(
  $limit: Int!
	$cursor: ID
	$searchTerm: String
  $relation: RelationFilter
) 
# @connection(key: "paginatedUsers", filter: ["relation"])
{
  paginatedUsers(
    limit: $limit
    cursor: $cursor
    searchTerm: $searchTerm
    relation: $relation
  ) {
    users {
      _id
      fullName
    }
    pageInfo {
      hasMore
      nextCursor
    }
  }
}
`;

export const CONFIRMED_GAMES = gql`
{
  confirmedGames {
    _id
    name
    open
    numbersRequiredMin
    options {
      datetime
      location
      players {
        _id
        fullName
      }
      playerCount
    }
    owner {
      fullName
      _id
    }
  }
}

`;
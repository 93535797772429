import React from "react";
import ConfettiLib from "react-confetti";
import useWindowDimensions from "utils/useWindowDimensions";

export default function Confetti(props) {
  const { animationDone, numberOfPieces, top } = props;
  const { height, width } = useWindowDimensions();

  return (
    <div
      style={{
        position: "absolute",
        top: top,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <ConfettiLib
        gravity={0.5}
        run={animationDone}
        numberOfPieces={numberOfPieces}
        width={width}
        height={height}
      />
    </div>
  );
}
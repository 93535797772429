import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { USER } from '../utils/queries';
import { useNavigate } from 'react-router-dom';
import FullScreenLoading from '../components/FullScreenLoading';
import { IconButton, ListItem, ListItemText, Typography, useMediaQuery } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import { Box, useTheme } from '@mui/system';
import Delete from '@mui/icons-material/Delete';
import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import dateFormat from '../utils/dateFormat';
import { SET_INVITE_STATUS, DELETE_INVITE } from '../utils/mutations';
import Auth from '../utils/auth';
import CircularGameProgress from '../components/CircularGameProgress';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import noMessages from '../assets/no-messages.png';
import { gameExpired, getInboxPillColor, renderPillText } from '../utils/optionHelpers';

export default function InviteList() {
  /* Router */
  let navigate = useNavigate();

  /* Style */
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const mailIconStyle = { color: "#0288d1", fontSize: '36px' };

  /* State */
  const [isSwiping, setIsSwiping] = useState(false);
  const [goToSignIn, setGoToSignin] = useState(false);

  /* Apollo Hooks */
  const { data, loading, error } = useQuery(USER);
  const [deleteInvite] = useMutation(DELETE_INVITE);
  const [setInviteStatus] = useMutation(SET_INVITE_STATUS);

  /* Handlers */
  const handleDelete = async (event, _id) => {
    event?.stopPropagation();

    if (Auth.getProfile()?.data?._id) {
      try {
        await deleteInvite({
          variables: { _id }
        });

      } catch (err) {
        console.log(err);
      }

    } else {
      setGoToSignin(true);
    }
  };

  const handleToggleMarkAs = async (event, invite) => {
    event?.stopPropagation();

    const status = invite.status === 'read' ? 'unread' : 'read';

    if (Auth.getProfile()?.data?._id) {
      try {
        await setInviteStatus({
          variables: { _id: invite._id, status }
        });

      } catch (err) {
        console.log(err);
      }

    } else {
      setGoToSignin(true);
    }

  };

  const handleClickListItem = (event, invite) => {
    event.preventDefault();
    if (!isSwiping) {
      handleToggleMarkAs(event, { _id: invite._id, status: 'unread' });
      navigate(`/inbox/${invite.game._id}`);
    }
  };

  const handleInfo = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // EDIT_ME ADD MODAL
  };

  const whiteOrGrey = invite => invite.status === 'read' || gameExpired(invite.game) ? '#AAB4BE' : 'white';

  if (error) {
    return <div>Something Went Wrong</div>;
  }

  if (loading) {
    return <FullScreenLoading />;
  }

  if (goToSignIn) {
    navigate('/signin');
  }

  return (
    <>
      <div className="to-top">
        <div style={{ position: 'sticky', top: '65px', padding: '16px 0', width: '100vw', zIndex: 1000, textAlign: 'center', backgroundColor: 'hsl(212, 61%, 3%)', borderBottom: '1px solid #132F4C', borderTop: '1px 0 solid #132F4C', }}>
          <ArrowBackIosIcon sx={{ position: 'absolute', left: '12px', top: '20px', cursor: 'pointer' }} onClick={() => navigate('/')} />
          <Typography variant={sm ? "h6" : "h4"} fontWeight='bold' component="h2" sx={{ flexGrow: 1, overflow: 'hidden' }}>
            Inbox
          </Typography>
        </div>
        <Box className="wide-box">
          {data.user.gameInvites.length > 0 && (
            <SwipeableList>
              {data.user.gameInvites.map(invite => (
                <SwipeableListItem
                  key={invite._id}
                  swipeLeft={{
                    content: <div>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        sx={{ marginRight: '12px' }}
                      >
                        <Delete sx={{ color: "#FF505F", fontSize: '36px' }} />
                      </IconButton>
                    </div>,
                    action: () => handleDelete(null, invite._id)
                  }}
                  swipeRight={{
                    content: <div>
                      <IconButton
                        edge="end"
                        aria-label="mark as read"
                        sx={{ marginRight: '12px' }}
                      >
                        {invite.status === 'unread' ? <DraftsOutlinedIcon sx={mailIconStyle} /> : <MailOutlineIcon sx={mailIconStyle} />}
                      </IconButton>
                    </div>,
                    action: () => handleToggleMarkAs(null, invite)
                  }}
                  onSwipeStart={() => setIsSwiping(true)}
                  onSwipeEnd={() => setTimeout(() => setIsSwiping(false), 10)}
                  blockSwipe={sm ? false : true}
                >
                  <ListItem
                    onClick={e => handleClickListItem(e, invite)}
                    sx={{ alignItems: 'baseline', color: whiteOrGrey(invite), borderBottom: "1px solid #132f4c", paddingRight: sm ? '16px' : '104px', "&:hover": { background: 'hsl(212, 61%, 9%)' } }}
                    secondaryAction={!sm &&
                      <>
                        {<IconButton
                          onClick={(e) => handleDelete(e, invite._id)}
                          edge="end"
                          aria-label="delete"
                          sx={{ marginRight: '12px' }}
                        >
                          <Delete />
                        </IconButton>}

                        <IconButton
                          onClick={(e) => handleToggleMarkAs(e, invite)}
                          edge="end"
                          aria-label={invite.status === 'unread' ? 'mark as read' : 'mark as unread'}
                        >
                          {invite.status === 'unread' ? <DraftsOutlinedIcon /> : <MailOutlineIcon />}
                        </IconButton>
                      </>
                    }
                  >
                    <Box className="d-flex flex-col justify-content-between align-items-center" sx={{ minWidth: '80px', maxWidth: '80px', paddingRight: '16px' }}>
                      <Typography component={"span"}>
                        {dateFormat(parseInt(invite.game.createdAt))}
                      </Typography>
                      <CircularGameProgress game={invite.game} status={invite.status} onClick={handleInfo} />
                    </Box>
                    <ListItemText
                      primary={invite.game.owner.fullName}
                      secondary={
                        <>
                          <span style={{ display: 'block', marginBottom: '8px', color: whiteOrGrey(invite), wordBreak: 'break-word' }}>
                            {invite.game.name}
                          </span>
                          <span style={{
                            display: 'inline-block',
                            padding: '0 8px',
                            marginTop: '2px',
                            borderRadius: '100vw',
                            color: 'black',
                            backgroundColor: invite.status === 'read' || gameExpired(invite.game) ? '#AAB4BE' :
                              getInboxPillColor(invite.game, data.user._id)
                          }}
                          >
                            {renderPillText(invite.game, data.user._id)}
                          </span>
                        </>
                      }
                    />
                  </ListItem>
                </SwipeableListItem>
              ))}
            </SwipeableList>
          )}
        </Box>
      </div>

      {data.user.gameInvites.length === 0 && (
        <div className='d-flex-center flex-col fullscreen'>
          <div style={{ textAlign: 'center', transform: 'translateY(65px)' }}>
            <img src={noMessages} alt='no messages' style={{ maxHeight: '50vh', maxWidth: '99vw' }} />
            <Typography variant={sm ? "p" : "h6"} component="h3" sx={{ flexGrow: 1, overflow: 'hidden' }}>
              No Messages
            </Typography>
          </div>
        </div>
      )}
    </>
  );
}

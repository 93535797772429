import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useConfirm } from 'utils/confirm/useConfirm';
import { CONFIRMED_GAMES, GAME, USER } from '../utils/queries';
import { useParams, useNavigate, Navigate, Link } from 'react-router-dom';
import { SELECT_OPTION, DESELECT_OPTION, DECLINE_INVITE, INVITE_PLAYERS } from '../utils/mutations';
import FullScreenLoading from '../components/FullScreenLoading';
import Auth from '../utils/auth';
import { useTheme } from '@mui/system';
import {
  Switch,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
  Box,
  Button,
} from '@mui/material';
import GameHeader from '../components/GameHeader';
import { gameOn, findWinningOption, userIsInWinningOption, gameExpired } from '../utils/optionHelpers';
import Confetti from '../components/Confetti.js';
import Loading from '../components/Loading';
import PlayersModal from '../components/PlayersModal';
import OptionPrimary from '../components/OptionPrimary';
import InviteFriends from 'components/InviteFriends';
import CopyInviteLinkToClipboard from 'components/CopyInviteLinkClipboard';

const Expired = () => {
  return (
    <Box sx={{ zIndex: 50, background: 'hsl(212, 61%, 3%, .8)', borderRadius: "5px", position: "absolute", top: 0, bottom: 0, left: 0, right: 0, textAlign: "center" }} className='d-flex-center'>
      <Typography fontWeight='bold' color="error" variant='overline'>EXPIRED</Typography>
    </Box>
  );
};

export default function Invite() {
  /* Style */
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.down('md'));

  /* State */
  const [animateConfetti, setAnimateConfetti] = useState(false);
  const [numPieces, setNumPieces] = useState(500);
  const [optionsLoading, setOptionsLoading] = useState([]); // array of optionIds
  const [open, setOpen] = useState(false);
  const [optionInfo, setOptionInfo] = useState({});
  const [invited, setInvited] = useState([]);

  /* Router */
  const { id: _id } = useParams();
  const navigate = useNavigate();


  /* Apollo Hooks */
  const { data, loading, error } = useQuery(GAME, {
    variables: { _id }
  });

  const [selectOption] = useMutation(SELECT_OPTION, {
    variables: { _id },
    refetchQueries: [
      { query: CONFIRMED_GAMES },
      { query: USER },
    ]
  });

  const [deselectOption] = useMutation(DESELECT_OPTION, {
    variables: { _id },
    refetchQueries: [
      { query: CONFIRMED_GAMES },
      { query: USER },
    ]
  });

  const [declineInvite] = useMutation(DECLINE_INVITE, {
    variables: { _id },
    refetchQueries: [
      { query: CONFIRMED_GAMES },
      { query: USER },
    ]
  });

  const [invitePlayers] = useMutation(INVITE_PLAYERS, {
    refetchQueries: [
      { query: CONFIRMED_GAMES },
      { query: USER },
    ],
  });

  /* Custom Confirm Hook */
  const { isConfirmed } = useConfirm();

  /* EFfects */
  useEffect(() => {
    const cleanup = () => {
      setNumPieces(500);
      setAnimateConfetti(false);
    };

    if (window) {
      window.addEventListener('resize', cleanup);
    }

    if (animateConfetti) {
      setAnimateConfetti(true);
      setTimeout(() => setNumPieces(0), 1200);
    }

    return () => {
      cleanup();
      window.removeEventListener('resize', cleanup);
    };
  }, [animateConfetti]);

  /*Auth */
  const user = Auth.loggedIn() ? Auth.getProfile().data : null;

  /* Handlers */
  const toggleInfoModal = (e, option) => {
    e.stopPropagation();
    setOpen(prev => !prev);
    setOptionInfo(option);
  };

  const handleToggleSelect = async (option) => {
    if (optionsLoading.includes(option._id) || !data.game) return;

    setOptionsLoading(prev => [...prev, option._id]);
    if (option.players.map(p => p._id).includes(user._id)) {
      setAnimateConfetti(false);
      // DESELECT
      try {
        if (option.playerCount === data.game.numbersRequiredMin) {
          const confirmed = await isConfirmed(`You really can't run? Don't be a flake.`, "I'm Out", "I'll Play");
          if (!confirmed) throw new Error('unconfirmed');
        }

        await deselectOption({
          variables: { optionId: option._id },
          optimisticResponse: {
            deselectOption: {
              ...data.game,
              options: data.game.options.map(o => {
                if (o._id !== option._id) {
                  return o;
                }
                return {
                  ...o,
                  players: o.players.filter(p => p._id !== option._id)
                };
              })
            }
          },
        });
      } catch (err) {
        console.log(err);
      }
      setOptionsLoading(prev => prev.filter(id => id !== option._id));
    } else {
      // SELECT
      try {
        if (option.playerCount + 1 === data.game.numbersRequiredMin) {
          const confirmed = await isConfirmed(`You sure you can run? Game on if you're in.`, `Ok`, `Cancel`);
          if (!confirmed) throw new Error('canceled');
        }

        const res = await selectOption({
          variables: { optionId: option._id },
          optimisticResponse: {
            selectOption: {
              ...data.game,
              out: data.game.out.filter(p => p._id !== user._id),
              options: data.game.options.map(o => {
                if (o._id !== option._id) {
                  return o;
                }
                return {
                  ...o,
                  players: [...o.players, { _id: user._id, fullName: user.fullName }],
                };
              })
            }
          },
        });

        const animate = gameOn(res.data.selectOption) && userIsInWinningOption(res.data.selectOption, user._id);

        setAnimateConfetti(animate);
      } catch (err) {
        console.log(err);
      }
      setOptionsLoading(prev => prev.filter(id => id !== option._id));
    }
  };

  // DECLINE INVITE
  const handleDeclineInvite = async () => {
    if (
      gameOn(data.game) &&
      userIsInWinningOption(data.game, user._id) &&
      findWinningOption(data.game).playerCount === data.game.numbersRequiredMin
    ) {
      const confirmed = await isConfirmed(`You really can't run? Don't be a flake.`, "I'm Out", "I'll Play");
      if (!confirmed) throw new Error('unconfirmed');
    }

    try {
      await declineInvite({
        optimisticResponse: {
          declineInvite: {
            ...data.game,
            out: [...data.game.out, { _id: user._id, fullName: user.fullName }],
            options: data.game.options.map(o => {
              return { ...o, players: o.players.filter(p => p._id !== user._id) };
            }),
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleInvitePlayers = async () => {
    try {
      await invitePlayers({
        variables: { _id, playerIds: invited.map(p => p._id) }
      });

      setInvited([]);

    } catch (err) {
      console.log(err);
    }
  };

  /* Helpers */
  const userSelected = option => option.players.map(p => p._id).includes(user._id);
  const isFull = option => option.playerCount === data?.game?.numbersRequiredMax;
  const isExpired = option => Date.now() >= parseInt(option.datetime) + 3600000;

  if (data === 'undefined') return <Navigate to='/inbox' />;
  if (!user) window.location.assign('/signin');
  if (loading) return <FullScreenLoading />;
  if (error) return <Navigate to='/inbox' />;

  // redirect uninvited to closed games only
  if (data?.game?.open === false && Auth.loggedIn() && !data?.game?.invited?.map(p => p._id).includes(Auth.getProfile().data._id)) navigate('/inbox');

  return (
    data?.game &&
    <>
      {animateConfetti && <Confetti animationDone={true} numberOfPieces={numPieces} top={200} />}

      <Box className='to-top'>
        {/* HEADER */}
        <GameHeader backTo='/inbox' game={data?.game} />
        {/* OPTIONS */}
        <Box className="wide-box" sx={{
          padding: md ? '16px 0' : '26px',
          marginBottom: '40px',
          marginTop: '36px',
        }}>
          <Box marginBottom={3}>
            <Typography variant={"overline"} component="h4" color="primary" fontWeight='bold' textAlign={'center'} fontSize={20}>
              {data.game.options.length > 1 ? "When Can You Run?" : "You In?"}
            </Typography>
            <List sx={{ width: '100%' }}>
              {(
                !gameOn(data?.game)
                  ? data?.game?.options
                  : [findWinningOption(data?.game)]
              ).map((option) => {
                const labelId = `checkbox-list-label-${option._id}`;
                return (
                  <ListItem
                    className='w-100'
                    key={option._id}
                    sx={{
                      margin: '0 0 12px 0',
                      border: "1px solid #132f4c",
                      boxShadow: "1px 1px 4px #132f4c",
                      borderRadius: "5px",
                      padding: '0',
                      '&:hover': { backgroundColor: 'hsl(212, 61%, 9%, 1)' }
                    }}
                    disablePadding={sm ? true : false}
                    disableGutters={sm ? true : false}
                  >
                    {isExpired(option) &&
                      <Expired />
                    }
                    <ListItemButton
                      onClick={() => handleToggleSelect(option)}
                      sx={{
                        borderRadius: '5px',
                        paddingLeft: '8px',
                        '&:hover': { backgroundColor: 'hsl(212, 61%, 9%, 1)' }
                      }}
                    >
                      {optionsLoading.includes(option._id) ? (
                        <div className='option-loading'>
                          <Loading />
                        </div>
                      ) : (
                        <Switch
                          edge="start"
                          checked={userSelected(option)}
                          disabled={isFull(option) && !userSelected(option)}
                          tabIndex={-1}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      )}
                      <ListItemText
                        id={labelId}
                        primary={
                          <OptionPrimary option={option} toggleInfoModal={toggleInfoModal} numbersRequiredMax={data?.game.numbersRequiredMax} />
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            {!gameExpired(data.game) &&
              <Button onClick={handleDeclineInvite} style={{ marginBottom: '16px' }} fullWidth variant='outlined' color={'error'}>
                <Switch
                  sx={{ position: 'absolute', left: '7px' }}
                  edge="start"
                  checked={data.game.out.map(x => x._id).includes(user._id)}
                  color="error"
                />
                I Can't Play
              </Button>
            }
          </Box>

          <Box marginBottom={3}>

            {data.game?.open && (
              <Box mb={2} padding={2} sx={{ border: '1px solid rgba(29, 180, 90, 0.5)', borderRadius: '4px' }}>
                <Box mb={2}>
                  <InviteFriends
                    offsetTop={-64}
                    invited={invited}
                    setInvited={setInvited}
                    inputLabel={"Invite More Players"}
                    filterOutOptions={[...data?.game?.invited, data?.game?.owner]}
                  />
                </Box>
                <Button onClick={handleInvitePlayers} fullWidth color='success' variant={'outlined'}>
                  Send
                </Button>
              </Box>
            )}
            {data?.game?.open && <CopyInviteLinkToClipboard gameId={data.game._id} />}
          </Box>

          <Link to='/inbox'>
            <Button fullWidth variant='outlined' color={'primary'}>
              {gameExpired(data.game) ? "GO BACK" : "DONE"}
            </Button>
          </Link>
        </Box>
      </Box>

      {/* OPTION INFO MODAL */}
      {optionInfo?.players &&
        <PlayersModal
          open={open}
          setOpen={setOpen}
          lists={
            [
              {
                items: optionInfo?.players || [],
                title: "Players"
              }
            ]
          }
        />
      }
    </>
  );
};

import React from 'react';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import PeopleIcon from '@mui/icons-material/People';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import dateFormat from 'utils/dateFormat';
import Auth from '../utils/auth';

export default function OptionPrimary({ option, toggleInfoModal, numbersRequiredMax }) {
  const user = Auth.loggedIn() ? Auth.getProfile().data : null;
  const userSelected = option => option.players.map(p => p._id).includes(user._id);
  const isFull = option => option.playerCount === numbersRequiredMax;
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <span style={{ display: 'flex', alignItems: 'center', fontSize: sm ? '14px' : '18px', fontWeight: '100', margin: '12px 0 12px' }}>
        <ScheduleOutlinedIcon style={{ transform: 'translateY(-3px)', marginRight: '18px' }} />
        {
          dateFormat(parseInt(option.datetime), "long")
        }
      </span>
      <span style={{ display: 'flex', alignItems: 'center', fontSize: sm ? '14px' : '18px', fontWeight: '100', margin: '0 0 12px' }}>
        <PlaceOutlinedIcon style={{ transform: 'translateY(-3px)', marginRight: '18px' }} />
        {option.location}
      </span>
      <span style={{ display: 'flex', alignItems: 'center', fontSize: sm ? '14px' : '18px', fontWeight: '100' }}>
        <IconButton
          onClick={(e) => toggleInfoModal(e, option)}
          sx={{ marginRight: '4px', transform: 'translateX(-6px)' }}
        >
          <PeopleIcon
            color="success"
          />
        </IconButton>
        {isFull(option)
          ? (userSelected(option) ? 'Full Game! 🥳' : ' No spots available 😓')
          : (`${option.playerCount} Player${option.playerCount === 1 ? '' : 's'}`)
        }
      </span>
    </>
  );
}

import React from "react";
import { useConfirm } from "../utils/confirm/useConfirm";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function ConfirmModal() {
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '50vh',
    width: '90%',
    maxWidth: '350px',
    bgcolor: 'hsl(212, 61%, 8%)',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    color: 'white',
    p: 4,
  };

  const {
    prompt = "Are You Sure?",
    isOpen = false,
    proceedText="Ok",
    proceed,
    cancelText = "Cancel",
    cancel,

  } = useConfirm();

  return (
    <Modal
      open={isOpen}
      onClose={cancel}
      aria-labelledby="confirm"
      aria-describedby="confirm"
    >
      <Box
        sx={modalStyle}
        component="form"
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            proceed();
          }
        }}
      >
        <Typography component={"h3"} variant="h6" textAlign={'center'}>
          {prompt}
        </Typography>
        <div className="d-flex">
          <Button
            sx={{ flex: 1, margin: '12px' }}
            color="primary"
            onClick={proceed}
            variant="outlined"
          >{proceedText}
          </Button>
          <Button
            sx={{ flex: 1, margin: '12px' }}
            color="secondary"
            onClick={cancel}
            variant="outlined"
          >{cancelText}
          </Button>
        </div>
        {/* 
        CODE_ME
          <Button color={'info'} onClick={stop}>
            dont show me this message again
          </Button> 
        */}
      </Box>
    </Modal>
  );
};


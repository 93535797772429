import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { USER } from '../utils/queries';
import { DELETE_GAME } from '../utils/mutations';
import { useNavigate, Link } from 'react-router-dom';
import FullScreenLoading from '../components/FullScreenLoading';
import { Button, IconButton, ListItem, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import Delete from '@mui/icons-material/Delete';
import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import dateFormat from '../utils/dateFormat';
import Auth from '../utils/auth';
import { useConfirm } from '../utils/confirm/useConfirm';
import CircularGameProgress from '../components/CircularGameProgress';
import {
  inviteMoreFriends,
  renderPillText,
  getManageGamesPillColor,
} from '../utils/optionHelpers';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import noGamesImg from '../assets/basketball-player.png';

export default function InviteList() {
  /* Router */
  const navigate = useNavigate();

  /* Style */
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  /* State */
  const [isSwiping, setIsSwiping] = useState(false);
  const [goToSignIn, setGoToSignin] = useState(false);

  /* Apollo Hooks */
  const { data, loading, error } = useQuery(USER);
  const [deleteGame] = useMutation(DELETE_GAME);

  /* Custom Confirm Hook */
  const { isConfirmed } = useConfirm();

  /* Handlers */
  const handleDelete = async (event, game) => {
    event?.stopPropagation();
    const confirmed = await isConfirmed("Are you sure?");

    if (!confirmed) return null;

    if (Auth.getProfile()?.data?._id === game?.owner?._id) {
      try {
        await deleteGame({
          variables: { _id: game._id },
          update: (cache, { data }) => {
            try {
              const pv = cache.readQuery({
                query: USER,
              });

              cache.writeQuery({
                query: USER,
                data: {
                  user: {
                    ...pv.user,
                    myGames: pv.user.myGames.filter(g => g._id !== data.deleteGame._id)
                  }
                }
              });
            } catch (err) {
              console.log(err);
            }
          },
        });


      } catch (err) {
        console.log(err);
      }

    } else {
      setGoToSignin(true);
    }
  };

  const handleClickListItem = (event, _id) => {
    event.preventDefault();
    if (!isSwiping) {
      navigate(`/manage/${_id}`);
    }
  };

  const handleInfo = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // EDIT_ME ADD MODAL
  };

  if (goToSignIn) {
    navigate('/signin');
  }

  if (error) {
    console.log(error);
    return <div>Something Went Wrong</div>;
  }

  if (loading) return <FullScreenLoading />;

  return (
    <>
      <div className="to-top">
        <div style={{ position: 'sticky', top: '65px', padding: '16px 0', width: '100vw', zIndex: 1000, textAlign: 'center', backgroundColor: 'hsl(212, 61%, 3%)', borderBottom: '1px solid #132F4C', borderTop: '1px 0 solid #132F4C' }}>
          <ArrowBackIosIcon sx={{ position: 'absolute', left: '12px', top: '20px', cursor: 'pointer' }} onClick={() => navigate('/')} />
          <Typography variant={sm ? "h6" : "h4"} fontWeight='bold' component="h2" sx={{ flexGrow: 1, overflow: 'hidden' }}>
            My Games
          </Typography>
        </div>
        <Box className="wide-box">
          {data.user.myGames.length > 0 && (
            <SwipeableList>
              {data.user.myGames.map(game => (
                <SwipeableListItem
                  key={game._id}
                  swipeLeft={{
                    content: <div>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        sx={{ marginRight: '12px' }}
                      >
                        <Delete sx={{ color: "#FF505F", fontSize: '36px' }} />
                      </IconButton>
                    </div>,
                    action: () => handleDelete(null, game)
                  }}
                  onSwipeStart={() => setIsSwiping(true)}
                  onSwipeEnd={() => setTimeout(() => setIsSwiping(false), 10)}
                  blockSwipe={sm ? false : true}
                >
                  <ListItem
                    onClick={e => handleClickListItem(e, game._id)}
                    sx={{ alignItems: 'baseline', borderBottom: "1px solid #132f4c", paddingRight: sm ? '16px' : '104px', "&:hover": { background: 'hsl(212, 61%, 9%)' } }}
                    secondaryAction={!sm &&
                      <>
                        {<IconButton
                          onClick={(e) => handleDelete(e, game)}
                          edge="end"
                          aria-label="delete"
                          sx={{ marginRight: '12px' }}
                        >
                          <Delete />
                        </IconButton>}
                      </>
                    }
                  >

                    <Box className="d-flex flex-col justify-content-between align-items-center" sx={{ minWidth: '80px', maxWidth: '80px', paddingRight: '16px' }}>
                      <Typography component={"span"}>
                        {dateFormat(parseInt(game.createdAt))}
                      </Typography>

                      <CircularGameProgress game={game} onClick={handleInfo} />
                    </Box>

                    <ListItemText
                      sx={{ wordBreak: 'break-word' }}
                      primary={game.name}
                      secondary={
                        <>
                          <span style={{ display: 'block', marginBottom: '8px' }}>
                            {game.invited.length} Players Invited
                          </span>
                          <span style={{
                            display: 'inline-block',
                            padding: '0 8px',
                            marginTop: '2px',
                            borderRadius: '100vw',
                            fontWeight: inviteMoreFriends(game) ? 'bold' : 'normal',
                            color: inviteMoreFriends(game) ? 'white' : 'black',
                            backgroundColor: getManageGamesPillColor(game),
                          }}
                          >
                            {
                              inviteMoreFriends(game)
                                ? "⚠ invite More Friends ⚠"
                                : renderPillText(game, data.user._id)
                            }
                          </span>
                        </>
                      }
                    />
                  </ListItem>
                </SwipeableListItem>
              ))}
            </SwipeableList>
          )}
        </Box>
      </div>
      {data.user.myGames.length === 0 && (
        <div className='d-flex-center flex-col fullscreen'>
          <div style={{ textAlign: 'center', transform: 'translateY(65px)' }}>
            <img src={noGamesImg} style={{ maxHeight: '50vh', maxWidth: '99vw' }} alt='no games to manage' />
            <div>
              <Link to="/create">
                <Button variant='outlined'>
                  Create Game
                </Button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { CreateGameIcon, ManageGameIcon, MyPeopleIcon, InvitesIcon, MyHomeIcon, AboutIcon, ConfirmedGamesIcon } from '../components/Icons';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { client } from '../App';
import { USER } from '../utils/queries';
import { gameExpired } from '../utils/optionHelpers';

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  /* State */
  const [rotation, setRotation] = useState(0);

  /* Style */
  const { window } = props;
  const { menuDrawerOpen, setMenuDrawerOpen } = props;

  /* Apollo Hooks */
  const { data } = useQuery(USER);
  const user = data?.user;

  /* Handlers */
  const handleDrawerToggle = () => {
    setMenuDrawerOpen(!menuDrawerOpen);
  };

  const handleClearStore = () => {
    setRotation(prev => prev - 360);

    client.resetStore();
  };

  /* Helpers */
  const getRoute = (text) => {
    switch (text) {
      case 'Home':
        return '/';
      case 'Create':
        return '/create';
      case 'Manage':
        return '/manage';
      case 'Inbox':
        return '/inbox';
      case 'Friends':
        return '/friends';
      case 'About':
        return '/about';
      case 'Game On':
        return '/game-on';
      default:
        return '/';
    }
  };

  const iconSize = 24;
  const drawer = (
    <div>
      <Toolbar sx={{ height: '64px' }}>
        <div onClick={handleDrawerToggle}>
          <MenuOpenIcon />
        </div>
      </Toolbar>
      <Divider />
      <List>
        {['Home', 'About', '|', 'Friends', 'Create', 'Manage', 'Inbox', '|', 'Game On'].map((text, index) =>
          text === '|' ? <Divider key={index} /> : (
            <Link key={index} to={getRoute(text)}>
              <ListItem button onClick={handleDrawerToggle}>
                <ListItemIcon>
                  {text === 'Home' && <MyHomeIcon sz={iconSize} />}
                  {text === 'About' && <AboutIcon sz={iconSize} />}
                  {text === 'Friends' && <MyPeopleIcon sz={iconSize} />}
                  {text === 'Create' && <CreateGameIcon sz={iconSize} />}
                  {text === 'Manage' && <ManageGameIcon sz={iconSize} />}
                  {text === 'Inbox' && <InvitesIcon sz={iconSize} countUnread={user?.gameInvites?.filter(invite => invite.status === 'unread' && !gameExpired(invite.game)).length} />}
                  {text === 'Game On' && <ConfirmedGamesIcon sz={iconSize} />}
                </ListItemIcon>
                <ListItemText primary={text} primaryTypographyProps={{ variant: "overline" }} />
              </ListItem>
            </Link>
          ))}
        <Divider />
        <ListItem button onClick={handleClearStore}>
          <ListItemIcon>
            <div style={{
              position: 'relative',
            }}>
              <CachedRoundedIcon sx={{
                fontSize: iconSize,
                transform: `rotate(${rotation}deg)`,
                transition: 'transform 800ms ease'
              }} />
            </div>
          </ListItemIcon>
          <ListItemText primary={'Refresh'} primaryTypographyProps={{ variant: "overline" }} />
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={menuDrawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: 'block',
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: 'hsl(212, 61%, 5%)',
            backgroundImage: 'none',
            opacity: .97,
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;

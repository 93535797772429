// REACT HOOKS
import React, { useCallback } from 'react';

// MUI
import { InputBase } from '@mui/material/';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import SyncIcon from '@mui/icons-material/Sync';

// DEBOUNCE
import debounce from 'lodash.debounce';

const Search = styled('div')(({ theme }) => ({
  borderRadius: '100vw',
  backgroundColor: alpha(theme.palette.divider, .75),
  '&:hover': {
    backgroundColor: alpha(theme.palette.divider, 1),
  },
  marginLeft: 0,
  width: '100%',
  marginBottom: '12px',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: 14,
  bottom: -2,
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: '48px',
    width: '100%',
  },
}));

export default function SearchBar(props) {
  const {
    searchTerm,
    setSearchTerm,
    setDebouncedSearchTerm,
    searchLoading,
    setSearchLoading,
  } = props;


  const handleSearchTermChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchLoading(true);
    setSearchTerm(nextValue);
    // Even though handleChange is created on each render and executed
    // it references the same debouncedSave that was created initially
    debouncedSave(nextValue);
  };
  
  // eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce(
      nextValue => {
        setSearchLoading(false);
        setDebouncedSearchTerm(nextValue);
      },
      500
    ),[]);

  return (
    <div className="search-users">
      <Search>
        <SearchIconWrapper>
          {searchLoading ? <SyncIcon className="spinning" /> : <SearchIcon />}
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          fullWidth
          onChange={handleSearchTermChange}
          value={searchTerm}
        />
      </Search>
    </div>
  );
}

import { createTheme } from '@material-ui/core';

export const theme =  createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#3399FF",
      light: "#66B2FF",
      dark: "#0059B2",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    divider: "#132F4C",
    primaryDark: {
      main: "#5090D3"
    },
    background: {
      default: "#001E3C",
      paper: "#132F4C"
    },
    common: {
      black: "#1D1D1D",
      white: "#fff"
    },
    text: {
      primary: "#fff",
      secondary: "#AAB4BE",
      disabled: "rgba(255, 255, 255, 0.5)",
      icon: "rgba(255, 255, 255, 0.5)"
    },
    error: {
      main: "#FF505F",
      light: "#FF99A2",
      dark: "#C70011",
      contrastText: "#fff"
    },
    success: {
      main: "#1DB45A",
      light: "#6AE79C",
      dark: "#1AA251",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    warning: {
      main: "#F1A204",
      light: "#FFE4A3",
      dark: "#F1A204",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    secondary: {
      main: "#ce93d8",
      light: "#f3e5f5",
      dark: "#ab47bc",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    info: {
      main: "#29b6f6",
      light: "#4fc3f7",
      dark: "#0288d1",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    neutral: {
      main: '#AAB4BE',
      contrastText: 'black',
    },

    contrastThreshold: 3,
    tonalOffset: 0.2,
    action: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(255, 255, 255, 0.16)",
      selectedOpacity: 0.16,
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(255, 255, 255, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.24
    }
  }
});

// Using '#AAB4BE' for greys
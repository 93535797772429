import React, { memo } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PeopleIcon from '@mui/icons-material/PeopleAltOutlined';
import { useMutation } from '@apollo/react-hooks';
import { ADD_FRIEND, REMOVE_FRIEND } from '../utils/mutations';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';

/* Helper Functions */
const regex = /\b\S/g;
const getAvatarText = (string) => {
  try {
    const array = string.match(regex);
    return array[0] + (array[1] ?? '');
  } catch (err) {
    console.log(err);
  }
};

function UserListItem(props) {
  const { user, relation, handleAddAndRemoveFriend } = props;
  // const [clicked, setClicked] = useState(false);
  // const hideOnClick = true

  /** APOLLO HOOKS */
  const [addFriend] = useMutation(ADD_FRIEND);
  const [removeFriend] = useMutation(REMOVE_FRIEND);
  const handleClick = () => {
    // setClicked(true);
    if (relation === "FRIENDS") {
      removeFriend({
        variables: { _id: user._id },
        update: handleAddAndRemoveFriend,
        optimisticResponse: {
          removeFriend: {
            __typename: "User",
            _id: user._id,
            fullName: user.fullName,
          }
        }
      });
    } else {
      addFriend({
        variables: { _id: user._id },
        update: handleAddAndRemoveFriend,
        optimisticResponse: {
          addFriend: {
            __typename: "User",
            _id: user._id,
            fullName: user.fullName,
          }
        }
      });
    }
  };

  return (
    <ListItem
      // style={{ display: (hideOnClick && clicked) ? 'none' : '' }}
      key={user._id}
      sx={{
        "&:not(:last-of-type)": {
          borderBottom: "1px solid #132f4c"
        }
      }}
      secondaryAction={
        <IconButton
          onClick={handleClick}
          edge="end"
          color={relation === "FRIENDS" ? 'success' : 'inherit'}
          aria-label={relation === "FRIENDS" ? 'remove friend' : 'add friend'}
        >
          {relation === "FRIENDS"
            ? <PeopleIcon />
            : <PersonAddIcon />
          }
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar sx={{ backgroundColor: '#132f4c', color: 'white' }}>
          <Typography variant="overline" component={"h4"}>
            {getAvatarText(user.fullName)}
          </Typography>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={user.fullName}
      />
    </ListItem>
  );
}

function areEqual(prevProps, nextProps) {
  if (
    prevProps.user._id === nextProps.user._id &&
    prevProps.relation === nextProps.relation
  ) return true;
  return false;
}

export default memo(UserListItem, areEqual);

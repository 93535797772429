import React, { useEffect, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Typography } from '@mui/material/';
import { SportsBasketball } from '@mui/icons-material';
import dateFormat from 'utils/dateFormat';
import { useQuery } from '@apollo/client';
import { CONFIRMED_GAMES } from '../utils/queries';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, Link } from 'react-router-dom';
import { useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { findWinningOption } from '../utils/optionHelpers';
import PeopleIcon from '@mui/icons-material/People';
import FullScreenLoading from '../components/FullScreenLoading';
import Auth from '../utils/auth';

const timeLineItemStyle = { '&::before': { content: 'none' } };
const monthNames = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};
const getMonthYear = (strDate) => monthNames[new Date(strDate).getMonth() + 1] + ' ' + new Date(strDate).getFullYear();

export default function ConfirmedGames() {
  const navigate = useNavigate();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const { data } = useQuery(CONFIRMED_GAMES);
  const [gamesByMonth, setGamesByMonth] = useState(null);
  const [fullScreenLoading, setFullScreenLoading] = useState(true);

  useEffect(() => {
    if (!data) return;
    if (data.confirmedGames.length === 0) {
      setGamesByMonth(null);
      setFullScreenLoading(false);
    }
    else {
      const gameMap = {};
      const confirmedGames = data.confirmedGames
        .map(game => {
          const option = findWinningOption(game);
          return {
            _id: game._id,
            name: game.name,
            owner: game.owner,
            datetime: option.datetime,
            location: option.location,
            players: option.players,
          };
        })
        .sort((a, b) => parseInt(b.datetime) - parseInt(a.datetime))
        ;

      confirmedGames.forEach(game => {
        const gameDate = new Date(parseInt(game.datetime));
        const key = new Date(gameDate.getFullYear(), gameDate.getMonth(), 1).toString();
        gameMap[key] = [...gameMap[key] || [], game];
      });

      setGamesByMonth(gameMap);
      setFullScreenLoading(false);
    }
  }, [data]);

  return (fullScreenLoading ? <FullScreenLoading /> :
    <React.Fragment>
      <div className='to-top'>
        <div style={{ position: 'sticky', top: '65px', padding: '16px 0', width: '100vw', zIndex: 1000, textAlign: 'center', backgroundColor: 'hsl(212, 61%, 3%)', borderBottom: '1px solid #132F4C', borderTop: '1px 0 solid #132F4C' }}>
          <ArrowBackIosIcon sx={{ position: 'absolute', left: '12px', top: '20px', cursor: 'pointer' }} onClick={() => navigate('/')} />
          <Typography variant={sm ? "h6" : "h4"} fontWeight='bold' component="h2" sx={{ flexGrow: 1, overflow: 'hidden' }}>
            Confirmed Games
          </Typography>
        </div>
        <div className='wide-box' style={{ maxWidth: '500px' }}>
          <Timeline
            position='right'
            sx={{ width: '100%', }}
          >
            {gamesByMonth ?
              Object.keys(gamesByMonth)
                .sort((a, b) => parseInt(b.datetime) - parseInt(a.datetime))
                .map((key) => (
                  <div key={key}>
                    <TimelineItem sx={{ ...timeLineItemStyle, marginLeft: '12px' }}>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot sx={{ background: '#3399FF' }} />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography variant='h6' lineHeight={4} sx={{ color: '#3399FF', fontWeight: 'bold' }}>
                          {getMonthYear(key)}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                    {gamesByMonth[key].map(game => (
                      <TimelineItem key={game._id} sx={timeLineItemStyle}>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot sx={{ background: 'hsl(212, 62%, 3%)' }}>
                            <SportsBasketball color='error' sx={{ fontWeight: 'bold', height: '100%', zIndex: 500 }} />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ paddingRight: 0 }}>
                          {/* GAME CARD START */}
                          <div style={{ padding: '18px', border: '1px solid #132F4C', borderRadius: '5px', backgroundColor: "hsl(212, 61%, 5%)" }}>
                            <Link to={`${game.owner._id === Auth.getProfile()?.data?._id ? '/manage' : '/inbox'}/${game._id}`}>
                              <Typography sx={{ textDecoration: 'underline', wordBreak: 'break-word' }} component='h4' fontWeight={'bolder'} variant='subtitle1'>{game.name}</Typography>
                            </Link>
                            <Typography variant='caption' fontWeight={100}>{game.owner.fullName}</Typography>

                            <div style={{ display: 'flex' }}>
                              <div>
                                <div style={{ display: 'flex', fontSize: sm ? '12px' : '14px', padding: '12px 0 6px 0' }}>
                                  <div className='d-flex-center' style={{ marginRight: '12px' }}>
                                    <ScheduleOutlinedIcon />
                                  </div>
                                  <div>
                                    <Typography fontWeight={400} lineHeight={1.6} component='p' variant='caption'>
                                      {dateFormat(parseInt(game.datetime), "longMonth")}
                                    </Typography>
                                    <Typography fontWeight={100} lineHeight={1.6} component='p' variant='overline'>
                                      {dateFormat(parseInt(game.datetime), "timeOnly")}
                                    </Typography>
                                  </div>
                                </div>

                                <div style={{ display: 'flex', fontSize: sm ? '12px' : '14px', padding: '12px 0 6px 0' }}>
                                  <div className='d-flex-center' style={{ marginRight: '12px' }}>
                                    <PlaceOutlinedIcon />
                                  </div>
                                  <div>
                                    <a target='_blank' href={`https://www.google.com/maps/place/${game.location.split(' ').join('+')}`} rel={'noreferrer'}>
                                      <Typography fontWeight={400} lineHeight={1.6} variant='caption' sx={{ color: '#29b6f6' }}>
                                        {game.location}
                                      </Typography>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={{ display: 'flex', fontSize: sm ? '12px' : '14px', padding: '12px 0 6px 0' }}>
                              <div className='d-flex-center' style={{ marginRight: '12px' }}>
                                <PeopleIcon />
                              </div>
                              <div>
                                {game.players.map((p, index) => (
                                  <span
                                    key={index}
                                    style={{
                                      display: 'inline-block',
                                      padding: '0px 6px',
                                      margin: '4px',
                                      borderRadius: '100vw',
                                      fontSize: '12px',
                                      backgroundColor: 'hsl(212, 61%, 16%)',
                                      color: 'ivory',
                                    }}
                                  >
                                    {p.fullName}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                          {/* GAME CARD END */}
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </div>
                )) : (
                // NO GAMES
                <>
                  <TimelineItem sx={{ ...timeLineItemStyle, marginLeft: '12px' }}>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot sx={{ background: '#3399FF' }} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant='h6' lineHeight={4} sx={{ color: '#3399FF', fontWeight: 'bold' }}>
                        No Games
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  {["Eat", "Sleep", "Hoop"].map((value, index) =>
                    <TimelineItem key={index} sx={{ ...timeLineItemStyle }}>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot sx={{ background: 'none' }}>
                          <SportsBasketball color='error' sx={{ fontWeight: 'bold', height: '100%', zIndex: 500 }} />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography variant='OVERLINE' color='secondary' lineHeight={4} sx={{ fontWeight: 'bold' }}>
                          {value}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  )}

                </>
              )}
          </Timeline >
        </div>
      </div >
    </React.Fragment >
  );
};
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
import Auth from "../utils/auth";

export default function Header({ setMenuDrawerOpen }) {
  return (
    <AppBar sx={{
      background: 'hsl(212, 61%, 5%)',
      color: 'white',
      borderBottom: '1px solid #132F4C',
      height: '65px',
      marginBottom: '16px',
    }}
      enableColorOnDark
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => setMenuDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="h1" sx={{ flexGrow: 1 }} className={'small-screen-hide'}>
          I Can Run Lowkey
        </Typography>

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>


        {Auth.loggedIn() ? (
          <Link to='/' style={{ color: "ivory", textDecoration: 'none' }}>
            <Button variant='text' color="inherit" onClick={() => Auth.logout()}>
              Logout
            </Button>
          </Link>
        ) : (
          <Link to='/signin' style={{ color: "ivory", textDecoration: 'none' }}>
            <Button color="inherit">Login</Button>
          </Link>
        )

        }
      </Toolbar>
    </AppBar>
  );
}

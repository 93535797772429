import React from 'react';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import PeopleIcon from '@mui/icons-material/PeopleAltOutlined'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import Avatar from '@mui/material/Avatar';
import HomeIcon from '@mui/icons-material/Home';
import StadiumOutlinedIcon from '@mui/icons-material/StadiumOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const cornerIconStyle = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  color: "#FFF",
  backgroundColor: 'hsl(212, 61%, 5%)',
  borderRadius: '50%',
};

export const CreateGameIcon = ({ sz }) => {
  return (
    <div style={{ position: 'relative' }}>
      <SportsBasketballIcon color="error" sx={{ fontSize: sz }} />
      <AddCircleOutline sx={{ ...cornerIconStyle, fontSize: sz / 2 }} />
    </div>
  );
};


export const ManageGameIcon = ({ sz }) => {
  return (
    <div style={{ position: 'relative' }}>
      <SportsBasketballIcon color="warning" sx={{ fontSize: sz }} />
      <SettingsOutlinedIcon sx={{ ...cornerIconStyle, fontSize: sz / 2 }} />
    </div>
  );
};

export const MyPeopleIcon = ({ sz }) => {
  return (
    <div style={{ position: 'relative' }}>
      <PeopleIcon color="success" sx={{ fontSize: sz }} />
    </div>
  );
};

export const InvitesIcon = ({ sz, countUnread }) => {
  return (
    <div style={{ position: 'relative' }}>
      <MailOutlinedIcon color="primary" sx={{ fontSize: sz }}/>
      {(countUnread > 0) &&
        <Avatar sx={{
          ...cornerIconStyle,
          top: 0,
          backgroundColor: '#FF505F',
          height: '40%',
          width: '40%',
          fontSize: sz / 5,
        }}>
          {countUnread}
        </Avatar>
      }
    </div>
  );
};

export const MyHomeIcon = ({ sz }) => {
  return (
    <div style={{ position: 'relative' }}>
      <HomeIcon sx={{ fontSize: sz, color: "white" }} />
    </div>
  );
};

export const ConfirmedGamesIcon = ({ sz }) => {
  return (
    <div style={{ position: 'relative' }}>
      <StadiumOutlinedIcon sx={{ fontSize: sz, color: "#7457e2" }} />
    </div>
  );
};

export const AboutIcon = ({ sz }) => {
  return (
    <div style={{ position: 'relative' }}>
      <HelpOutlineOutlinedIcon sx={{ fontSize: sz, color: "#B0BEC5" }} />
    </div>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GAME, CONFIRMED_GAMES } from '../utils/queries';
import { DELETE_OPTION, UPDATE_GAME } from '../utils/mutations';
import { useConfirm } from '../utils/confirm/useConfirm';
import { useParams } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import FullScreenLoading from '../components/FullScreenLoading';
import Auth from '../utils/auth';
import { useTheme } from '@mui/system';
import { ListItem, ListItemText, Typography, IconButton, useMediaQuery, Box, Slider, FormLabel, Button, Fade, TextField, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import Delete from '@mui/icons-material/Delete';
import InviteFriends from '../components/InviteFriends';
import OptionInput from '../components/OptionInput';
import GameHeader from '../components/GameHeader';
import PlayersModal from '../components/PlayersModal';
import { gameOn, findWinningOption } from 'utils/optionHelpers';
import OptionPrimary from '../components/OptionPrimary';
import CopyInviteLinkToClipboard from 'components/CopyInviteLinkClipboard';

export default function ManageGameItem() {
  /* Router */
  const { id: _id } = useParams();
  const navigate = useNavigate();

  /*Auth */
  const user = Auth.getProfile()?.data;

  /* State */
  // Game Options
  const [open, setOpen] = useState(false);
  const [optionInfo, setOptionInfo] = useState({});

  // Edit Game
  const [name, setName] = useState('');
  const [openGame, setOpenGame] = useState('');
  const [sliderActive, setSliderActive] = useState(false);
  const [numbersRequired, setNumbersRequired] = useState([]);
  const [invited, setInvited] = useState([]);
  const [gameOptions, setGameOptions] = useState([]);

  /* Style */
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.down('md'));

  const styles = {
    wideBox: {
      padding: md ? '16px 0' : '26px',
      marginBottom: '40px',
      marginTop: '36px',
    },
    formItem: {
      boxSizing: 'content-box',
      padding: '16.5px 14px',
      borderRadius: '4px',
      border: '1px solid #3f444a',
      boxShadow: '1px solid #3f444a',
      ':hover': {
        border: '1px solid white',
      },
    },
    formItemSlider: {
      boxSizing: 'content-box',
      padding: '16.5px 14px',
      borderRadius: '4px',
      border: sliderActive ? '1px solid #3090ef' : '1px solid #3f444a',
      boxShadow: sliderActive ? '0 0 0 1px #3090ef' : '1px solid #3f444a',
      ':hover': {
        border: sliderActive ? '1px solid #3090ef' : '1px solid white',
      },
    },
  };

  /* Apollo Hooks */
  const { data, loading, error } = useQuery(GAME, {
    variables: { _id },
  });

  const [deleteOption] = useMutation(DELETE_OPTION, {
    refetchQueries: [{
      query: CONFIRMED_GAMES
    }]
  });

  const [updateGame, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_GAME, {
    refetchQueries: [{
      query: CONFIRMED_GAMES
    }]
  });

  /* Custom Confirm Hook */
  const { isConfirmed } = useConfirm();

  /* Handlers */
  // DELETE OPTION
  const handleDeleteOption = async (event, optionId) => {
    const confirmed = await isConfirmed("Are you sure?");
    if (!confirmed) return null;
    if (data.game.owner._id === user._id) {
      try {
        await deleteOption({
          variables: {
            _id: data.game._id,
            optionId
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const toggleInfoModal = (e, option) => {
    e.stopPropagation();
    setOpen(prev => !prev);
    setOptionInfo(option);
  };


  // NAME 
  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleChangeOpen = (e) => {
    setOpenGame(e.target.value);
  };


  /* Slider Handler Start */
  const isTouchScreen = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
  const maxValue = 20;
  const minValue = 2;
  const sliderRef = useRef(null);
  const getClamp = (x, v, activeThumb) => {
    const l = sliderRef.current.getBoundingClientRect().left;
    const w = sliderRef.current.getBoundingClientRect().width;
    if (activeThumb === 0) {
      return Math.floor(Math.max(Math.min((x - l) / w * (maxValue - minValue) + minValue, maxValue), v));
    } else {
      return Math.ceil(Math.min(Math.max((x - l) / w * (maxValue - minValue) + minValue, minValue), v));
    }
  };

  const handleChangeSlider = (event, newValue, activeThumb) => {
    // Type safety
    if (!Array.isArray(newValue)) {
      return;
    };

    // Type safety
    if (!sliderRef.current) {
      console.log('Issue: !sliderRef.current');
      return;
    }

    // Solves Mobile Bug: mousedown event on touchscreen causes slider to snap back
    if (isTouchScreen && event.type === 'mousedown') {
      return;
    }

    // Use Material UI Default When Range
    if (newValue[1] - newValue[0] > 0) {
      setNumbersRequired(newValue);
    }

    // Custom Slider Clamping Logic When Together
    else {
      const x = event.clientX || event.touches[0].clientX;
      const v = newValue[0];
      const clamped = getClamp(x, v, activeThumb);
      setNumbersRequired([clamped, clamped]);
    }
  };
  /* Slider Handler End */

  // ADD OPTION
  const handleClickAddOption = () => {
    setGameOptions(prev => [...prev, { location: "", datetime: null }]);
  };

  // SUBMIT
  const handleSubmit = async (event) => {
    event.preventDefault();

    const game = {
      _id,
      name,
      open: openGame === "TRUE",
      options: [...gameOptions],
      invited: invited.map(x => x._id),
      numbersRequiredMin: parseInt(numbersRequired[0]),
      numbersRequiredMax: parseInt(numbersRequired[1]),
    };

    try {
      await updateGame({ variables: { game } });

      setInvited([]);
    } catch (err) {
      console.log(err);
    }
  };


  /* Effects */
  // Populate Initial Edit Game state when Game data is fetched
  useEffect(() => {
    if (data) {
      const game = data.game;
      setName(data.game.name);
      setOpenGame(data.game?.open ? "TRUE" : "FALSE");
      setNumbersRequired([game.numbersRequiredMin, game.numbersRequiredMax]);
      setGameOptions([]);
      if (Auth.getProfile()?.data?._id !== data?.game?.owner?._id) navigate('/manage');
    }
  }, [data, user._id, navigate]);

  /* Error Catching */
  if (loading) return <FullScreenLoading />;
  if (error) return <Navigate to='/manage' />;

  /* Render */
  return (
    updateLoading ? <FullScreenLoading /> : <>
      <Box className='to-top'>
        {/* HEADER */}
        <GameHeader game={data?.game} backTo='/manage' />
        {/* OPTIONS */}
        <Box className="wide-box" sx={styles.wideBox}>
          <Typography variant={sm ? "h6" : "h5"} fontWeight='bold' component="h4" color="primary">
            Game Options
          </Typography>
          <SwipeableList sx={{ width: '100%' }}>
            {(
              !gameOn(data.game)
                ? data.game.options
                : [findWinningOption(data.game)]
            ).map((option) => {
              const labelId = `checkbox-list-label-${option._id}`;
              return (
                <SwipeableListItem
                  key={option._id}
                  swipeLeft={{
                    content: <div>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        sx={{ marginRight: '12px' }}
                      >
                        <Delete sx={{ color: "#FF505F", fontSize: '36px' }} />
                      </IconButton>
                    </div>,
                    action: () => handleDeleteOption(null, option._id)
                  }}
                  blockSwipe={data.game.options.length === 1 || (sm ? false : true)}
                >
                  <ListItem
                    className='w-100'
                    key={option._id}
                    sx={{
                      margin: '12px 0',
                      border: "1px solid #132f4c",
                      boxShadow: "1px 1px 4px #132f4c",
                      borderRadius: "5px",
                      padding: '10px 10px 0px 10px ',
                    }}
                    disablePadding={sm ? true : false}
                    disableGutters={sm ? true : false}
                    secondaryAction={!sm &&
                      <>
                        {data.game.options.length > 1 && <IconButton
                          onClick={(event) => handleDeleteOption(event, option._id)}
                          edge="end"
                          aria-label="delete"
                          sx={{ marginRight: '12px' }}
                        >
                          <Delete />
                        </IconButton>}
                      </>
                    }
                  >
                    <ListItemText
                      id={labelId}
                      primary={
                        <Box paddingX={2} mb={1}>
                          <OptionPrimary option={option} toggleInfoModal={toggleInfoModal} numbersRequiredMax={data?.game.numbersRequiredMax} />
                        </Box>
                      }
                    />
                  </ListItem>
                </SwipeableListItem>
              );
            })}
          </SwipeableList>
          <Box sx={{
            margin: '12px 0',
            border: "1px solid #132f4c",
            boxShadow: "1px 1px 4px #132f4c",
            borderRadius: "5px",
            padding: '10px',
          }}>
            <Typography component='h4' variant='p' marginBottom={1} >
              {data.game.invited.length > 0 ? "Invited" : "Invite Some Friends!"}
            </Typography>
            {data.game.invited.map(p => {
              const out = data.game.out.map(x => x._id).includes(p._id);
              const playing = findWinningOption(data.game).players.map(x => x._id).includes(p._id);
              return (
                <span
                  key={p._id}
                  style={{
                    display: 'inline-block',
                    padding: '2px 8px',
                    margin: '0 6px 6px 0',
                    borderRadius: '100vw',
                    fontSize: '13px',
                    backgroundColor: out
                      ? '#AAB4BE' //grey
                      : playing
                        ? '#1DB45A' //green success
                        : '#29b6f6', //green blue info
                    textDecoration: out
                      ? 'line-through'
                      : 'none',
                    color: 'black',
                  }}
                  title={out ? "can't play" : "invited"}
                >
                  {p.fullName}
                </span>
              );
            })
            }
          </Box>


          <Box>
            <Typography sx={{ alignSelf: 'baseline' }} color="white" variant={sm ? "h6" : "h5"} fontWeight='bold' component="h4">
              Edit Game
            </Typography>
          </Box>

          <Box sx={{
            ...styles.wideBox,
            backgroundColor: 'hsl(212, 61%, 5%)',
            border: '1px solid #132F4C',
            borderRadius: '5px',
            padding: sm ? '16px' : '32px',
            marginTop: '18px'
          }}
            component="form"
            onSubmit={handleSubmit}
          >
            <TextField
              name="name"
              type="text"
              label={'Name'}
              color={'primary'}
              fullWidth
              value={name}
              onChange={handleChangeName}
            />

            {/* SLIDER */}
            <Box
              component='div'
              sx={styles.formItemSlider}
              onMouseDown={() => setSliderActive(true)}
              onMouseUp={() => setSliderActive(false)}
            >
              <FormLabel
                sx={{ color: sliderActive ? '#3090ef' : 'none' }}
              >
                {
                  `Players Needed (${numbersRequired[0] === numbersRequired[1]
                    ? numbersRequired[0]
                    : numbersRequired[0] + " - " + numbersRequired[1]})`
                }
              </FormLabel>
              <Slider
                ref={sliderRef}
                getAriaLabel={() => 'min max numbers required slider'}
                name="numbersRequired"
                type="number"
                label={'Players Needed'}
                color={'primary'}
                step={1}
                min={minValue}
                max={maxValue}
                value={numbersRequired}
                valueLabelDisplay="off"
                onChange={handleChangeSlider}
                disableSwap
              />
            </Box>

            <Box sx={styles.formItem}>
              <FormControl fullWidth>
                <FormLabel id="open-or-closed-game">
                  {openGame === "TRUE" ? "Anyone With Link Can Join" : "Only You Can Invite Friends"}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="open"
                  defaultValue={'TRUE'}
                  name="open"
                  value={openGame}
                  onChange={handleChangeOpen}
                >
                  <FormControlLabel value={'TRUE'} control={<Radio />} label="Open" />
                  <FormControlLabel value={"FALSE"} control={<Radio />} label="Closed" />
                </RadioGroup>
              </FormControl>
            </Box>

            <InviteFriends offsetTop={-64} filterOutOptions={data.game.invited} inputLabel={"Invite More Friends"} invited={invited} setInvited={setInvited} />
            {data?.game?.open && <CopyInviteLinkToClipboard gameId={data.game._id} />}

            {gameOptions.map((x, i) => <OptionInput
              deletableFirstOption={true}
              setGameOptions={setGameOptions}
              gameOptions={gameOptions}
              i={i}
              key={i}
            />)}

            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={handleClickAddOption}
            >
              Add Option
            </Button>

            <Button type='submit' fullWidth variant='outlined'>
              Submit
            </Button>

            {typeof updateError !== 'undefined' && <Fade in={typeof updateError !== 'undefined'} timeout={1000}>
              <Typography component='p' variant='subtitle1' color="error">
                Something went wrong.
              </Typography>
            </Fade>

            }
          </Box>
        </Box>
      </Box>

      {/* OPTION INFO MODAL */}
      {optionInfo?.players &&
        <PlayersModal
          open={open}
          setOpen={setOpen}
          lists={
            [
              {
                items: optionInfo.players,
                title: "Players"
              }
            ]
          }
        />
      }
    </>
  );
}

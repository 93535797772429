const addDateSuffix = date => {
  let dateStr = date.toString();

  // get last char of date string
  const lastChar = dateStr.charAt(dateStr.length - 1);

  if (lastChar === '1' && dateStr !== '11') {
    dateStr = `${dateStr}st`;
  } else if (lastChar === '2' && dateStr !== '12') {
    dateStr = `${dateStr}nd`;
  } else if (lastChar === '3' && dateStr !== '13') {
    dateStr = `${dateStr}rd`;
  } else {
    dateStr = `${dateStr}th`;
  }

  return dateStr;
};

// function to format a timestamp, accepts the timestamp and an `options` object as parameters
module.exports = (
  timestamp,
  style = "short"
) => {

  const dateObj = new Date(timestamp);

  const dayOfMonth = dateObj.getDate();

  const monthMap = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  const month = dateObj.getMonth() + 1;
  const monthName = monthMap[month]

  const dayMap = {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
    0: "Sun",
  }

  const day = dayMap[dateObj.getDay()]

  let hour = dateObj.getHours();
  if (hour > 12) hour -= 12;
  if (hour === 0) hour = 12;

  let minute = ("0" + dateObj.getMinutes().toString()).slice(-2);
  
  const periodOfDay = dateObj.getHours() >= 12 ? 'pm' : 'am';

  const today = new Date().setHours(0, 0, 0, 0);
  const createdOnDate = new Date(timestamp).setHours(0, 0, 0, 0);

  if (style === "long") {
    return `${day}, ${monthName} ${addDateSuffix(dayOfMonth)} ${hour}:${minute}${periodOfDay}`
  }
  
  if (style === "med") {
    return `${day}, ${month}/${dayOfMonth} ${hour}:${minute}${periodOfDay}`
  }
  
  if (style === "longMonth") {
    return `${day}, ${monthName} ${addDateSuffix(dayOfMonth)}`
  }
  
  if (style === "timeOnly") {
    return `${hour}:${minute} ${periodOfDay}`
  }
  
  if (style === "dateOnly") {
    return `${monthMap[month]} ${dayOfMonth}`;
  }

  if (today === createdOnDate) {
    return `${hour}:${minute}${periodOfDay}`;
  }

  return `${monthMap[month]} ${dayOfMonth}`;

};

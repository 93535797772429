import React, { useState, useEffect } from 'react';
import { Button, TextField, Fade, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { Box } from '@mui/system';
import { useMutation } from '@apollo/client';
import { CREATE_USER, LOGIN } from '../utils/mutations';
import { Link } from 'react-router-dom'
import Auth from '../utils/auth';
import { normalizePhoneInput } from '../utils/helpers'

export default function Signin() {
  /* Style */
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  /* State */
  const [newUser, setNewUser] = useState(false);
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });
  const [displayPhone, setDisplayPhone] = useState('');
  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validPhone, setValidPhone] = useState(true);
  const [unmatchedPassword, setUnmatchedPassword] = useState(false);

  /* Mutation */
  const [CreateUser, { error: signupError }] = useMutation(CREATE_USER);
  const [Login, { error: loginError }] = useMutation(LOGIN);

  /* Effects */
  useEffect(() => {
    setDisplayPhone(normalizePhoneInput(form.phone));
  }, [form.phone]);

  /* Handlers */
  const handleChange = (event) => {
    if (event.target.name === 'phone') {
      setForm({
        ...form,
        phone: event.target.value.split(/\D/).join('').substring(0, 10)
      });

      setDisplayPhone(normalizePhoneInput(form.phone));
    } else {
      setForm({
        ...form,
        [event.target.name]: event.target.value
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setUnmatchedPassword(false);
    if (newUser) {
      if (form.password === form.confirmPassword) {
        try {
          const { data } = await CreateUser({
            variables: {
              ...form
            }
          });

          Auth.login(data.createUser.token);

        } catch (err) {
          console.log(err);
        }
      } else {
        setUnmatchedPassword(true);
      }
    } else {
      try {
        const { data } = await Login({
          variables: {
            email: form.email,
            password: form.password,
          }

        });

        Auth.login(data.login.token);
      } catch (err) {
        console.log(err);
        console.log(loginError);
      }
    }
  };

  const handlePhoneError = (e) => {
    const regexp = /^\d{10}$/gi;
    const valid = form.phone.trim().match(regexp)
      ? (form.phone.trim().match(regexp)[0] === form.phone)
      : false;
    setValidPhone(valid);
  };

  const handleFirstNameError = (e) => {
    e.target.value.length === 0 ? setValidFirstName(false) : setValidFirstName(true);
  };

  const handleLastNameError = (e) => {
    e.target.value.length === 0 ? setValidLastName(false) : setValidLastName(true);
  };

  const handleEmailError = (e) => {
    const regexp = /^\S+@\S+\.\S+$/gi;
    const valid = e.target.value.trim().match(regexp)
      ? (e.target.value.trim().match(regexp)[0] === e.target.value.trim())
      : false;
    setValidEmail(valid);
  };

  return (
    <div className='vertical-center' style={{
      width: sm ? '95%' : '80%',
      maxWidth: '550px',
      top: newUser ? 'max(50%, 400px)' : 'max(50%, 258px)',
      padding: '48px 0',
    }}>
      <Box
        component="form"
        id="signup-form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'hsl(212, 61%, 5%)',
          border: '1px solid #132F4C',
          borderRadius: '5px',
          padding: sm ? '16px' : '32px',
        }}
        onSubmit={handleSubmit}
      >
        <Typography fontWeight='bold' variant={sm ? "h6" : "h4"} component="h2" sx={{ flexGrow: 1 }}>
          {newUser ? "Start Running" : "Keep Running"} <span>
            <DirectionsRunIcon fontSize="16px" className='translate-down-5px' />
          </span>
        </Typography>

        {newUser && <TextField
          name="firstName"
          type="text"
          label={validFirstName ? 'First Name' : 'Please enter your first name'}
          color={validFirstName ? 'primary' : 'error'}
          fullWidth
          value={form.firstName}
          onChange={handleChange}
          onBlur={handleFirstNameError}
        />}

        {newUser && <TextField
          name="lastName"
          type="text"
          label={validLastName ? 'Last Name' : 'Please enter your last name'}
          color={validLastName ? 'primary' : 'error'}
          fullWidth
          value={form.lastName}
          onChange={handleChange}
          onBlur={handleLastNameError}
        />}

        <TextField
          name="email"
          type="email"
          label={validEmail ? 'Email' : 'Enter a valid email'}
          color={validEmail ? 'primary' : 'error'}
          fullWidth
          value={form.email}
          onChange={handleChange}
          onBlur={handleEmailError}
        />

        {newUser && <TextField
          name="phone"
          type="tel"
          label={validPhone ? 'Phone' : 'Enter a valid phone number'}
          color={validPhone ? 'primary' : 'error'}
          value={displayPhone}
          onChange={handleChange}
          fullWidth
          onBlur={handlePhoneError}
        />}

        <TextField
          name="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          value={form.password}
          onChange={handleChange}
          fullWidth
        />

        {newUser && <TextField
          color={form.password === form.confirmPassword ? 'success' : 'warning'}
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          autoComplete="current-password"
          value={form.confirmPassword}
          onChange={handleChange}
          fullWidth
        />}

        <Button variant="outlined" type="submit">
          {newUser ? "Signup" : "Login"}
        </Button>

        {!newUser && <Link to='/reset-password'>
          <Typography size='small' variant='caption' color='lightgray' sx={{ cursor: 'pointer' }}>
            Forgot Your Password? It Happens...
          </Typography>
        </Link>}


        {unmatchedPassword && <Fade in={unmatchedPassword} timeout={1000}>
          <Typography component='p' variant='subtitle1' color='error'>
            Your passwords don't match.
          </Typography>
        </Fade>}

        {typeof loginError != "undefined" && !newUser && <Fade in={typeof loginError != "undefined"} timeout={1000}>
          <Typography component='p' variant='subtitle1' color='error'>
            Wrong username or password.
          </Typography>
        </Fade>}

        {typeof signupError != "undefined" && newUser && <Fade in={typeof signupError != "undefined"} timeout={1000}>
          <Typography component='p' variant='subtitle1' color="error">
            {signupError.message.includes('E11000 duplicate key')
              ? "A user with this email already exists."
              : "Something went wrong. Please review your inputs."
            }
          </Typography>
        </Fade>}
      </Box>
      <Typography component='p' mt={3}>
        {newUser ? "Already have an account?" : "Don't have an account?"}
        <Button onClick={() => setNewUser(!newUser)}>
          {newUser ? "Login" : "Signup"}
        </Button>
      </Typography>
    </div>
  );
}


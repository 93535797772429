import React, { useEffect, useState } from 'react';
import { Checkbox, Chip, Autocomplete, TextField, Stack, useMediaQuery, useTheme, Box, ListItem } from '@mui/material';
import { useQuery } from '@apollo/client';
import { USER } from '../utils/queries';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { filterArrByArr } from '../utils/optionHelpers';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function InviteFriends({
  invited,
  setInvited,
  inputLabel = "Invite Friends",
  filterOutOptions = [],
  offsetTop = 0,
}) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { loading, data } = useQuery(USER);
  const friends = data?.user.friends || [];
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState(0);
  const disableWindowScroll = () => window.scrollTo(0, 0);

  useEffect(() => {
    if (open) {
      if (xs) {
        document.addEventListener('scroll', disableWindowScroll);
      }
    } else {
      document.removeEventListener('scroll', disableWindowScroll);
    }
    return () => {
      document.removeEventListener('scroll', disableWindowScroll);
    };
  }, [open, xs]);

  const handleOpen = (event) => {
    if (xs) {
      setScroll(window.scrollY);
      window.scroll(0, 0);
    }
    setOpen(true);
  };

  const handleClose = (event) => {
    setOpen(false);
    if (xs) {
      window.scroll(0, scroll);
    }
  };

  const handleSelect = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Stack spacing={3}>
        <Autocomplete
          sx={(open && xs) ? {
            position: 'absolute',
            top: 0 + offsetTop,
            left: 0,
            width: 'calc(100% - 32px)',
            margin: '16px',
            zIndex: 1201,
          } : {}}
          id="async-invite-friends-autocomplete"
          multiple
          disableCloseOnSelect
          disablePortal
          options={filterArrByArr(friends, filterOutOptions)}
          loading={loading}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionLabel={option => typeof option === 'string' ? option : option.fullName}
          value={invited}
          onChange={(event, newValue) => setInvited(newValue)}
          inputValue={inputValue}
          onInputChange={(event, newValue) => setInputValue(newValue)}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          onSelect={handleSelect}
          popupIcon={false}
          ListboxProps={{
            sx: { maxHeight: 'calc(100vh * 0.33)', overflowX: 'hidden' },
          }}
          renderOption={(props, option, { selected }) => (
            <ListItem width={'100%'} {...props} key={option._id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.fullName}
            </ListItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              color='success'
              label={inputLabel}
              variant={'outlined'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? "..." : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          renderTags={(value, getTagProps) => {
            const values = value.map((option, index) => (
              <Chip
                label={option.fullName}
                size="small"
                {...getTagProps({ index })}
              />
            ));

            if (open && values.length) {
              return ["...", values.slice(values.length - 1)];
            }

            return values;
          }}
        />
      </Stack>
      {/* Backdrop */}
      {(open && xs) && (
        <Box sx={{
          background: 'hsl(212, 61%, 3%)',
          position: 'absolute',
          top: -16 + offsetTop,
          right: -16,
          left: -16,
          bottom: 0,
          zIndex: 1200,

        }} />
      )}
    </>
  );
}

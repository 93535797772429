import React, { useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { PAGINATED_USERS, USER } from '../utils/queries';
import { Typography } from '@mui/material/';
import UserListItem from './UserListItem';
import Loading from './Loading';
import { Waypoint } from 'react-waypoint';
import { mergeAndDedupe } from '../utils/helpers';

export default function Friends(props) {
  const { relation, searchTerm, listTitle, limit } = props;

  const variables = useMemo(() => {
    return {
      cursor: null,
      limit,
      relation,
      searchTerm,
    };
  }, [limit, relation, searchTerm])

  const otherRelation = { relation: relation === 'FRIENDS' ? 'NON_FRIENDS' : 'FRIENDS' };
  const otherVariables = {
    ...variables,
    relation: otherRelation.relation
  };

  /* APOLLO HOOKS */
  // PAGINATED_USERS QUERY
  const { data, error, fetchMore, refetch, networkStatus, previousData } = useQuery(PAGINATED_USERS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch(variables)
  }, [variables, refetch])

  const users = data
    ? data.paginatedUsers.users
    : (
      previousData
        ? previousData.paginatedUsers.users
        : []
    );

  // UPDATE ON ADD OR REMOVE FRIEND
  const handleAddAndRemoveFriend = (cache, { data: mutationData }) => {
    let action;
    if (mutationData.removeFriend) {
      action = 'removeFriend';
    }
    else if (mutationData.addFriend) {
      action = 'addFriend';
    }

    try {
      // update FRIENDS AND NON_FRIENDS CACHE
      // read THIS_QUERIES DATA
      const { paginatedUsers } = cache.readQuery({
        query: PAGINATED_USERS,
        variables,
      });
      
      const { paginatedUsers: paginatedUsersOtherList } = cache.readQuery({
        query: PAGINATED_USERS,
        variables: otherVariables,
      });

      // ADD TO OTHER LIST  //FRIENDS OR NON_FRIENDS
      cache.writeQuery({
        query: PAGINATED_USERS,
        variables: otherVariables,
        data: {
          paginatedUsers: {
            ...paginatedUsersOtherList,
            users: mergeAndDedupe(paginatedUsersOtherList.users, { ...mutationData[action] })
          }
        }
      });

      // REMOVE FROM THIS LIST  //FRIENDS OR NON_FRIENDS
      cache.writeQuery({
        query: PAGINATED_USERS,
        variables,
        data: {
          paginatedUsers: {
            ...paginatedUsers,
            users: paginatedUsers.users.filter(user => {
              return user._id !== mutationData[action]._id;
            })
          }
        }
      });


      // UPDATE LOGGED IN USER
      const { user } = cache.readQuery({
        query: USER
      });

      cache.writeQuery({
        query: USER,
        data: {
          user: {
            ...user,
            // if action is addFriend add else remove
            friends: action === 'addFriend'
              ? mergeAndDedupe(mutationData[action], user.friends)
              : user.friends.filter(f => f._id !== mutationData.removeFriend._id)
          },
        },
      });

    } catch (error) {
      console.log(error);
    }
  };

  if (error) return null;
  if (!users || users.length <= 0) return null;
  if (!data) return null;

  return (
    (users && users.length > 0) &&
    <React.Fragment>
      <Typography
        sx={{ zIndex: 200, fontSize: '20px' }}
        className="people-subtitle sticky-subtitle"
        variant="subtitle"
        component="h3"
      >
        {listTitle}
      </Typography>
      <div>
        {users.map(user =>
          <UserListItem
            style={{ marginTop: '20px' }}
            key={user._id}
            user={user}
            relation={relation}
            handleAddAndRemoveFriend={handleAddAndRemoveFriend}
            hideOnClick={true}
          />
        )}
      </div>

      {data.paginatedUsers.pageInfo.hasMore && (
        <div style={{ height: '80px', width: '100%', display: 'flex', justifyContent: 'center' }}>
          {networkStatus === 7 && <Waypoint onEnter={() =>
            fetchMore({
              variables: { ...variables, cursor: data.paginatedUsers.pageInfo.nextCursor },
              // move updateQuery to InMemoryCache.typePolicies per Apollo Client 3 rec
              updateQuery: (pv, { fetchMoreResult: nx }) => {
                if (!nx) return pv;
                return {
                  paginatedUsers: {
                    __typename: "UserPage",
                    pageInfo: { ...nx.paginatedUsers.pageInfo },
                    users: mergeAndDedupe(pv.paginatedUsers.users, nx.paginatedUsers.users)
                  }
                };
              }
            })
          }
          />}
          {networkStatus === 3 && <Loading />}
        </div>
      )}
    </React.Fragment>
  );
}


export const mergeAndDedupe = (a1 = [], a2 = []) => {
  // convert to hashmap
  const hashmap = {};

  const arr1 = (Array.isArray(a1)) ? a1 : [{...a1}];
  const arr2 = (Array.isArray(a2)) ? a2 : [{...a2}];

  [...arr1, ...arr2].forEach(ob => {
    hashmap[ob._id] = ob;
  });

  return Object.values(hashmap);
};

/* Utils */
export const normalizePhoneInput = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {

    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};
import React, { useCallback, useEffect } from 'react';
import Auth from '..//utils/auth';
import { useNavigate, useLocation } from "react-router-dom";
import { useLocalStorage } from '../utils/useLocalStorage';

export default function NavigationLogic() {
  const navigate = useNavigate();
  const location = useLocation();
  const [lastPath, setLastPath] = useLocalStorage('lastPath', '/');

  const goToLastPath = useCallback(() => {
    return navigate(lastPath);
  }, [lastPath, navigate]);

  const goToLogin = useCallback(() => {
    return navigate('/signin');
  }, [navigate]);

  useEffect(() => {
    if (['/signin', '/reset-password'].includes(location.pathname)) {
      // for links to redirect after signing in
      if (Auth.loggedIn()) goToLastPath();
    } else {
      // track users last visited path
      setLastPath(location.pathname);
      const unprotectedRoutes = ['/about', '/reset-password'];

      if (unprotectedRoutes.includes(location.pathname)) return;

      // else redirect user to login page w/ saved pathname so
      // signing in will redirect them back where there wanted to go
      if (!Auth.loggedIn()) goToLogin();
    }
  }, [location, setLastPath, goToLastPath, goToLogin]);

  return <></>;
}

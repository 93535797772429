import React, { useState } from 'react';
import { Autocomplete, Button, TextField, useMediaQuery } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PlacesAutoComplete from 'react-places-autocomplete';

export default function OptionInput(props) {
  const {
    i,
    setGameOptions,
    gameOptions,
    deletableFirstOption = false,
  } = props;
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  /* GOOGLE PLACES */
  const [open, setOpen] = useState(false);
  const [addressObject, setAddressObject] = useState(null);

  const handleChangeDatetime = (newValue) => {
    setGameOptions(
      Object.values({
        ...gameOptions,
        [i]: { ...gameOptions[i], datetime: newValue }
      })
    );
  };

  const handleChangeLocation = (newValue) => {
    setGameOptions(
      Object.values({
        ...gameOptions,
        [i]: { ...gameOptions[i], location: newValue }
      })
    );
  };

  return (
    <Box
      component="div"
      className="div-inputs"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        border: '1px solid rgba(206, 147, 216, 0.5)',
        borderRadius: '5px',
        padding: sm ? `${gameOptions.length === 1 ? '12px' : '1px'} 12px 12px 12px` : `${gameOptions.length === 1 ? '18px' : '1px'} 18px 18px 18px`,
      }}
    >
      {gameOptions.length > (deletableFirstOption ? 0 : 1) &&
        <div style={{ textAlign: 'right', height: '14px' }}>
          <Button
            sx={{ width: '28px', minWidth: '28px' }}
            size="small"
            color="error"
            aria-label="delete-option"
            onClick={() => {
              setGameOptions([
                ...gameOptions.slice(0, i), ...gameOptions.slice(i + 1)
              ]);
            }}>
            <DeleteOutlineOutlinedIcon sx={{ color: "error" }} />
          </Button>
        </div>
      }
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          renderInput={(props) => <TextField {...props} />}
          label="When"
          type="time"
          name="date-time"
          value={gameOptions[i]?.datetime}
          clearable
          disablePast
          onChange={(newValue) => handleChangeDatetime(newValue)}
        />

      </LocalizationProvider>
      <PlacesAutoComplete
        value={gameOptions[i]?.location}
        onChange={() => null}
        debounce={300}
        googleCallbackName="renderPlacesAutoCompleteFn"
      >
        {({ getInputProps, suggestions, loading }) => (
          <Autocomplete
            freeSolo
            disableCloseOnSelect={false}
            blurOnSelect={true}
            clearOnEscape={true}
            selectOnFocus={true}
            id="async-where-autocomplete"
            isOptionEqualToValue={(option, value) => option.description === value.description}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.description
            }
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={(event) => {
              if (suggestions?.length > 0 && event.type === "keydown") {
                handleChangeLocation(suggestions[0].description);
                setAddressObject(suggestions[0]);
              }
              setOpen(false);
            }}
            options={suggestions?.length ? suggestions : []}
            loading={loading}

            value={addressObject}
            onChange={(event, newValue) => {
              setAddressObject(newValue);
            }}

            inputValue={gameOptions[i].location}
            onInputChange={(event, newInputValue) => {
              handleChangeLocation(newInputValue);
            }}
            handleHomeEndKeys={true}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                color='primary'

                {...getInputProps({
                  className: 'location-search-input',
                })}

                label="Where"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        )}
      </PlacesAutoComplete>
    </Box>
  );
}

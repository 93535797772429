import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes as Switch, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles'; //TEST
import { theme } from 'theme';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { setContext } from '@apollo/client/link/context';
import Signin from './pages/Signin';
import Home from './pages/Home';
import About from 'pages/About';
import Friends from './pages/Friends';
import CreateGame from './pages/CreateGame';
import InviteList from './pages/InviteList';
import Invite from './pages/Invite';
import ManageGameList from './pages/ManageGameList';
import ManageGame from './pages/ManageGame';
import ConfirmedGames from 'pages/ConfirmedGames';
import BannedAccount from './pages/BannedAccount';
import Header from './components/Header';
import Menu from './components/Menu';
import FullScreenLoading from './components/FullScreenLoading';
import ConfirmModal from './components/ConfirmModal';
import NavigationLogic from 'components/NavigationLogic';
import useWindowDimensions from './utils/useWindowDimensions';
import ConfirmContextProvider from './utils/confirm/ConfirmContextProvider';
import ForgotPassword from 'pages/ForgotPassword';
import Auth from './utils/auth';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import PullToRefresh from 'react-simple-pull-to-refresh';
// import Loading from 'components/Loading';


const wsUri = process.env.NODE_ENV === 'production'
  ? `wss://icanrunlowkey.herokuapp.com/graphql`
  : `ws://localhost:3001/graphql`
  ;

const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    reconnect: true,
  },
});

const httpLink = new HttpLink({
  uri: `/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('id_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  };
});


const link = split(
  ({ query }) => {
    const def = getMainDefinition(query);
    return (
      def.kind === 'OperationDefinition' &&
      def.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  request: (operation) => {
    const token = localStorage.getItem('id_token');
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    });
  },

  link: authLink.concat(link),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          paginatedUsers: {
            keyArgs: ["relation", "searchTerm", "@connection", ["_id"]],
          },
        },
      },
      User: {
        fields: {
          friends: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          gameInvites: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Option: {
        fields: {
          players: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Game: {
        fields: {
          out: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          invited: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});


function App() {
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);
  const { height } = useWindowDimensions();
  // const pullToRefresh = useMediaQuery('(max-width:600px)');

  const bannedPhoneNumbers = ["3105707838"];

  return (
    // banned accounts  
    (Auth.loggedIn() && bannedPhoneNumbers.includes(Auth.getProfile()?.data?.phone)) ? (
      <div className="App" style={{ height: height }}>
        <div className="main">
          <BannedAccount />
        </div>
      </div>
    ) : (
      // application
      <ApolloProvider client={client}>
        <ConfirmContextProvider>
          <ConfirmModal />
          <Router>
            <NavigationLogic />
            <ThemeProvider theme={theme}>
              <Menu menuDrawerOpen={menuDrawerOpen} setMenuDrawerOpen={setMenuDrawerOpen} />
              <div className="App" style={{ height: height }}>
                <Header setMenuDrawerOpen={setMenuDrawerOpen} />
                {/* <PullToRefresh
                resistance={2.5}
                onRefresh={() => window?.location.reload()}
                refreshingContent={
                  <div style={{ 
                    height: '75px', 
                    marginTop: '80px', 
                    zIndex: 20,
                    }}>
                    <Loading />
                  </div>
                }
                isPullable={pullToRefresh}
              > */}
                <div className="main">
                  <Switch>
                    <Route path="/signin" element={<Signin />} />
                    <Route path="/reset-password" element={<ForgotPassword />} />
                    <Route path="/create" element={<CreateGame />} />
                    <Route path="/manage" element={<ManageGameList />} />
                    <Route path="/manage/:id" element={<ManageGame />} />
                    <Route path="/friends" element={<Friends />} />
                    <Route path="/inbox" element={<InviteList />} />
                    <Route path="/inbox/:id" element={<Invite />} />
                    <Route path="/game-on/" element={<ConfirmedGames />} />
                    <Route path="/about/" element={<About />} />
                    <Route path="/loading" element={<FullScreenLoading />} />
                    <Route path="/" element={<Home />} />
                    <Route path='/*' element={<Navigate to='/' />} />
                  </Switch>
                </div>
                {/* </PullToRefresh> */}
              </div>
            </ThemeProvider>
          </Router>
        </ConfirmContextProvider>
      </ApolloProvider>
    )
  );
}

export default App;

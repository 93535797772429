import gql from 'graphql-tag';

export const CREATE_USER = gql`
mutation CreateUser(
  $firstName: String!
  $lastName: String!
  $email: String!
  $phone: String!
  $password: String!
) {
  createUser(firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, password: $password) {
    user {
      _id
      fullName
    }
    token
  }
}
`;

export const LOGIN = gql`
mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    user {
      _id
      fullName
    }
    token
  }
}
`;

export const UPDATE_PASSWORD = gql`
mutation UpdatePassword($email: String!, $oldPassword: String!, $newPassword: String!) {
  updatePassword(email: $email, oldPassword: $oldPassword, newPassword: $newPassword) {
    user {
      _id
      fullName
    }
    token
  }
}
`;

export const ADD_FRIEND = gql`
mutation AddFriend($_id: ID!) {
  addFriend(_id: $_id) {
    _id
    fullName
  }
}
`;

export const REMOVE_FRIEND = gql`
mutation RemoveFriend($_id: ID!) {
  removeFriend(_id: $_id) {
    _id
    fullName
  }
}
`;

export const CREATE_GAME = gql`
mutation CreateGame(
  $name: String!
  $open: Boolean
  $numbersRequiredMin: Int!
  $numbersRequiredMax: Int!
  $timezoneOffset: Int!
  $invited: [ID]
  $options: [OptionInput]
) {
  createGame(
    name: $name
    open: $open
    numbersRequiredMin: $numbersRequiredMin
    numbersRequiredMax: $numbersRequiredMax
    timezoneOffset: $timezoneOffset
    options: $options
    invited: $invited
  ) {
    _id
    name
    open
    numbersRequiredMin
    numbersRequiredMax
    createdAt
    owner {
      _id
      fullName
    }
    invited {
      _id
      fullName
    }
    out {
      _id
      fullName
    }
    options {
      _id
      playerCount
      datetime
      location
      players {
        _id
        fullName
      }
    }
  }
}
`;

export const SET_INVITE_STATUS = gql`
mutation SetInviteStatus($_id: ID!, $status: String!) {
  setInviteStatus(_id:$_id, status:$status) {
    _id
    gameInvites {
      _id
      status
    }
  }
}
`;

export const DELETE_INVITE = gql`
mutation DeleteInvite($_id: ID!) {
  deleteInvite(_id: $_id) {
    _id
    gameInvites {
      _id
    }
  }
}
`;

export const SELECT_OPTION = gql`
mutation SelectOption($_id: ID!, $optionId: ID!) {
  selectOption(_id: $_id, optionId: $optionId) {
    _id
    name
    numbersRequiredMin
    numbersRequiredMax
    createdAt
    owner {
      _id
      fullName
    }
    invited {
      _id
      fullName
    }
    out {
      _id
      fullName
    }
    options {
      _id
      playerCount
      datetime
      location
      players {
        _id
        fullName
      }
    }
  }
}
`;

export const DESELECT_OPTION = gql`
mutation DeselectOption($_id: ID!, $optionId: ID!) {
  deselectOption(_id: $_id, optionId: $optionId) {
    _id
    name
    numbersRequiredMin
    numbersRequiredMax
    createdAt
    owner {
      _id
      fullName
    }
    invited {
      _id
      fullName
    }
    out {
      _id
      fullName
    }
    options {
      _id
      playerCount
      datetime
      location
      players {
        _id
        fullName
      }
    }
  }
}
`;

export const DELETE_GAME = gql`
mutation DeleteGame(
	$_id: ID!
) {
  deleteGame(_id: $_id) {
    _id
  }
}
`;

export const DELETE_OPTION = gql`
mutation DeleteOption(
  $_id: ID!
  $optionId: ID!
) {
  deleteOption(
    _id: $_id
    optionId: $optionId
  ) {
    _id
    options {
      _id
    }
  }
}
`;

export const DECLINE_INVITE = gql`
mutation DeclineInvite($_id: ID!) {
  declineInvite(_id: $_id) {
    _id
    name
    numbersRequiredMin
    numbersRequiredMax
    createdAt
    owner {
      _id
      fullName
    }
    invited {
      _id
      fullName
    }
    out {
      _id
      fullName
    }
    options {
      _id
      playerCount
      datetime
      location
      players {
        _id
        fullName
      }
    }
  }
}
`;

export const UPDATE_GAME = gql`

mutation updateGame($game: GameInput!) {
  updateGame(game: $game) {
    _id
    name
    open
    numbersRequiredMin
    numbersRequiredMax
    createdAt
    owner {
      _id
      fullName
    }
    invited {
      _id
      fullName
    }
    out {
      _id
      fullName
    }
    options {
      _id
      playerCount
      datetime
      location
      players {
        _id
        fullName
      }
    }
  }
}
`;

export const INVITE_PLAYERS = gql`
mutation InvitePlayers($_id: ID!, $playerIds: [ID!]!) {
  invitePlayers(_id: $_id, playerIds: $playerIds) {
    _id
    invited {
      _id
      fullName
    }
  }
}

`;
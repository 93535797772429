// REACT HOOKS
import React, { useState } from 'react';
// MUI
import Box from '@mui/material/Box';
// COMPONENTS
import SearchBar from '../components/SearchBar';
import UserList from '../components/UserList';

export default function PeopleRefactor() {
  /** STATE */
  // SEARCH BAR
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  // INITIAL LOAD
  const limit = 50;
  
  return (
    <>
      <div className="to-top">
        <Box className="wide-box" style={{ marginBottom: '80px' }}>
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            debouncedSearchTerm={debouncedSearchTerm}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
            searchLoading={searchLoading}
            setSearchLoading={setSearchLoading}
          />

          {/* FRIENDS */}
          <UserList
            relation="FRIENDS"
            searchTerm={debouncedSearchTerm}
            limit={limit}
            listTitle={"Friends"}
          />
          <UserList
            relation="NON_FRIENDS"
            searchTerm={debouncedSearchTerm}
            limit={limit}
            listTitle={"Users"}
          />
        </Box>
      </div>
    </>
  );
}

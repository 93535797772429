import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Auth from '../utils/auth.js';

export default function GameHeader({ game, backTo = '/' }) {
  const navigate = useNavigate() 

  return <div style={{
    position: 'sticky',
    top: '65px',
    padding: '16px 16px 16px 32px',
    width: '100vw',
    zIndex: 1000,
    textAlign: 'center',
    backgroundColor: 'hsl(212, 61%, 3%)',
    borderBottom: '1px solid #132F4C',
    borderTop: '1px solid #132F4C',
    maxHeight: '25vh',
    wordBreak: 'break-word',
    overflow: 'scroll',
  }}>
    <ArrowBackIosIcon sx={{ position: 'absolute', left: '12px', bottom: '20px', cursor: 'pointer' }} onClick={() => navigate(backTo)} />
    <Typography variant="overline" fontWeight='bold' lineHeight={1.6} fontSize={18} component="h2" mb={2}>
      {game?.name}
    </Typography>
    <Typography variant={"overline"} fontWeight='bold' component="h3" lineHeight={1.6}>
      {game?.owner?._id === Auth.getProfile().data._id ? "Edit Game" : `From: ${game?.owner.fullName}`}
    </Typography>
    <Typography variant={"overline"} fontWeight='bold' component="h4" lineHeight={1.6}>
      {game?.numbersRequiredMin === game?.numbersRequiredMax
        ? game?.numbersRequiredMin + " Player Game"
        : game?.numbersRequiredMin + "-" + game?.numbersRequiredMax + " Player Game"
      }
    </Typography>
  </div>;
}

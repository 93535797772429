import React from 'react';
import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import SmallPeopleList from './SmallPeopleList';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

export default function PlayersModal(props) {
  const {
    open,
    setOpen,
    lists = [
      {
        title: "Playing",
        items: [],
      }
    ],
  } = props;

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    maxWidth: '880px',
    maxHeight: '50vh',
    background: 'hsl(212, 61%, 7%)',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    color: 'white',
    p: sm ? 2 : 4,
    overflowY: 'auto',
  };

  return (
    <Modal
      disableRestoreFocus
      open={open}
      onClose={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
      aria-labelledby="see players attending"
    >
      <Box sx={modalStyle}>
        {
          lists.map((list, i) => {
            return list.items.length > 0 && <SmallPeopleList players={list.items} listTitle={list.title} key={i} />;
          })
        }
      </Box>
    </Modal>
  );
}

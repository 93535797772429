import React, { createContext, useState } from "react";

export const ConfirmContext = createContext([])

const ConfirmContextProvider = ({ children }) => {
  const [confirm, setConfirm] = useState({
    prompt: "Are You Sure?",
    isOpen: false,
    proceed: null,
    proceedText: "Ok",
    cancel: null,
    cancelText: "Cancel",
    dontShowMeAgain: false,
  });

  return (
    <ConfirmContext.Provider value={[confirm, setConfirm]}>
      {children}
    </ConfirmContext.Provider>
  );
};
export default ConfirmContextProvider;
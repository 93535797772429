import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { USER, CONFIRMED_GAMES } from '../utils/queries';
import { CreateGameIcon, ManageGameIcon, MyPeopleIcon, InvitesIcon, ConfirmedGamesIcon, AboutIcon } from '../components/Icons';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import FullScreenLoading from '../components/FullScreenLoading';
import { gameExpired } from '../utils/optionHelpers';

export default function Home() {
  /*Apollo Hooks */
  const { data, loading, error } = useQuery(USER);
  const user = data?.user;
  /* Preload Queries */
  useQuery(CONFIRMED_GAMES);

  /* Style */
  const theme = useTheme();
  const xxs = useMediaQuery(theme.breakpoints.down('310'));
  const xs = useMediaQuery(theme.breakpoints.down('400'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const lg = useMediaQuery(theme.breakpoints.down('lg'));

  

  const rSz = () => {
    switch (true) {
      case xxs:
        return 60;
      case xs:
        return 80;
      case sm:
        return 100;
      case md:
        return 120;
      case lg:
        return 140;
      default:
        return 160;
    }
  };



  const buttonStyle = {
    display: 'block',
    height: '100%',
    width: '100%',
    color: 'white',
    paddingTop: '18px',
  };

  return (
    loading ? <FullScreenLoading /> : <>

      <div style={{ position: 'absolute', top: '65px', padding: '16px 0', width: '100vw', zIndex: 1000, textAlign: 'center', backgroundColor: 'hsl(212, 61%, 3%)', borderBottom: '1px solid #132F4C', borderTop: '1px solid #132F4C' }}>
        <Typography component="h2" variant={sm ? "h6" : "h4"} color={error ? 'error' : 'white'}>
          {!error ? user.fullName : 'Check Wifi Connection'}
        </Typography>
      </div>

      <div className="main-grid vertical-center">
        <Link className="btn" to='/about'>
          <Button sx={buttonStyle}>
            <AboutIcon sz={rSz()} />
            <Typography component="h3" variant="overline">
              About
            </Typography>
          </Button>
        </Link>

        <Link className="btn" to="/friends">
          <Button sx={buttonStyle}>
            <MyPeopleIcon sz={rSz()} />
            <Typography component="h3" variant="overline">
              Friends
            </Typography>
          </Button>
        </Link>

        <Link className="btn" component="div" to="/create">
          <Button sx={buttonStyle}>
            <CreateGameIcon sz={rSz()} />
            <Typography component="h3" variant="overline">
              Create
            </Typography>
          </Button>
        </Link>

        <Link className="btn" to='/manage'>
          <Button sx={buttonStyle}>
            <div style={{ position: 'relative' }}>
              <ManageGameIcon sz={rSz()} />
            </div>
            <Typography component="h3" variant="overline">
              Manage
            </Typography>
          </Button>
        </Link>

        <Link className="btn" to='/inbox'>
          <Button sx={buttonStyle}>
            <InvitesIcon sz={rSz()} countUnread={error ? 0 : user.gameInvites.filter(invite => invite.status === 'unread' && !gameExpired(invite.game)).length} />
            <Typography component="h3" variant="overline">
              Inbox
            </Typography>
          </Button>
        </Link>

        <Link className="btn" to='/game-on'>
          <Button sx={buttonStyle}>
            <ConfirmedGamesIcon sz={rSz()} />
            <Typography component="h3" variant="overline">
              Game On
            </Typography>
          </Button>
        </Link>
      </div>
    </>
  );
}

import React from 'react';
import { CreateGameIcon, ManageGameIcon, MyPeopleIcon, InvitesIcon, ConfirmedGamesIcon } from '../components/Icons';
import { Link } from 'react-router-dom';
import { Button, Divider, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Auth from 'utils/auth';

const sz = 35;
const flexWrap = { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' };


const Article = (props) => (
  <article style={{ width: '350px', textAlign: 'center', padding: '32px' }}>
    <header>
      <div style={{ width: sz, margin: '0 auto' }}>{props.Icon}</div>
      <h4 style={{ textAlign: 'center', marginBottom: '12px' }}>
        {props.title}
      </h4>
    </header>
    <p style={{ color: '#c5ced7' }}>
      {props.body}
    </p>
  </article>
);

export default function About() {
  return (
    <>
      <div className='to-top'>
        {/* HEADER */}
        <div style={{
          position: 'sticky',
          top: '65px',
          padding: '16px 26px',
          width: '100vw',
          zIndex: 1000,
          textAlign: 'center',
          backgroundColor: 'hsl(212, 61%, 3%)',
          borderBottom: '1px solid #132F4C',
          borderTop: '1px 0 solid #132F4C',
          marginBottom: '48px'
        }}>
          <Link to={Auth.loggedIn() ? '/' : '/signup'}>
            <ArrowBackIosIcon sx={{ position: 'absolute', left: '12px', top: '25px', cursor: 'pointer' }} />
          </Link>
          <Typography variant={"h4"} fontWeight='bold' component="h2">
            About
          </Typography>
        </div>
        <section >
          <Typography variant={"h4"} fontWeight='500' component="h3" color='primary' sx={{ textAlign: 'center', margin: '8px 0 24px auto' }}>
            {/* NEED AN EASIER WAY TO ORGANIZE PICK UP BALL AROUND YOUR BUSY FRIENDS' SCHEDULES? */}
            Need an easy way to organize pick up?
          </Typography>

          <Typography variant={"h5"} color={'#FF505F'} fontWeight='500' component="h4" sx={{ textAlign: 'center', margin: '8px auto 0 auto', width: '80%' }}>
            Tired of reading through group texts to see who can run what days?
          </Typography>
        </section>


        <section className='wide-box' style={{ marginTop: '48px' }}>
          <div>
            <Typography variant={"h6"} fontWeight='500' component="h4" sx={{ textAlign: 'center', width: '100%' }}>
              How It Works
            </Typography>
          </div>
          <Divider />

          <div style={flexWrap}>
            <Article
              title="FRIENDS"
              body="Find your people so you can invite them to games. Don't wanna organize? No worries, let your friends find you."
              Icon={<MyPeopleIcon sz={sz} />}
            />

            <Article
              title="CREATE"
              body='Create a game with options for the time and place. Once one option gets enough players, game on. If an option fills up, no more players can join.'
              Icon={<CreateGameIcon sz={sz} />}
            />

            <Article
              title="MANAGE"
              body="Not getting the numbers you need? Try adding options or inviting more friends. Can't attend your own game? Delete it. Manage and track all your games."
              Icon={<ManageGameIcon sz={sz} />}
            />

            <Article
              title="INBOX"
              body="Check out games you've been invited to. Sign up before the game fills up and make sure to select as many options as you can play."
              Icon={<InvitesIcon sz={sz} />}
            />

            <Article
              title="GAME ON"
              body="Once enough players have joined an option, we run it. You'll find confirmed games here... time, place, players, etc."
              Icon={<ConfirmedGamesIcon sz={sz} />}
            />
          </div>
        </section>
        <footer style={{ marginTop: '50px' }}>
          <Link to={Auth.loggedIn() ? '/' : '/signup'}>
            <Button variant="outlined" sx={{marginBottom: '50px'}}>
              BALL IS LIFE
            </Button>
          </Link>
        </footer>

        {/* OPTIONS */}
      </div>
    </>
  );
}
